import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, makeStyles, Typography } from '@material-ui/core';
import { SectionHeader } from '../../common/molecules';
import { CardBase } from '../../common/organisms';
import CustomButton from '../../components/CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: "white"
  },
  cardArea1: {
    paddingTop: '50px',
    paddingBottom: '100px',
  },
  cardArea2: {
    padding: 0
  },
  margin: {
    marginTop: 10,
    marginBottom: 0
  },
  textJustify: {
    textAlign: 'justify'
  }
}));

const UseCases = props => {
  const { data, learnMoreLink, mainTitle, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={mainTitle || "Case Study"}
        titleClasses={classes.textWhite}
        subtitleProps={{
          className: classes.textWhite
        }}
      />
      <Grid
        justify="center"
        className={isMd ? classes.cardArea1 : classes.cardArea2} 
        container 
        spacing={isMd ? 4 : 2}
      >
        {data.map((item, index) => (
          <Grid item xs={12} md={4} key={index} data-aos="fade-up" component="a" href={learnMoreLink}>
            <CardBase liftUp noShadow variant="outlined">
              <Typography
                component="span"
                variant="body1"
                className={classes.textJustify}
              >
                {item.subtitle}
              </Typography>
              {learnMoreLink && 
                <CustomButton
                  className={classes.margin}
                  label="Read Full Case Study"
                  href={learnMoreLink}
                />
              }
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

UseCases.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default UseCases;
