import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { SectionHeader } from '../../../common/molecules';
import { SectionAlternate } from '../../../common/organisms';
import ContactForm from '../../../components/ContactForm/ContactForm'
import ExperiencesSection from '../../../components/ExperiencesSection/ExperiencesSection';
import Partners from '../../../components/Partners/Partners';
import { experiences } from '../../LandingPage/data/data';

const useStyles = makeStyles(theme => ({
  mainHeader: {
    height: 600,
    borderRadius: '0 0 100% 0',
    background: theme.palette.background.gradient3
  },
  mainHeaderTitle: {
    color: 'white',
    marginBottom: '20px',
    textAlign: 'left'
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  marginBottom: {
    marginBottom: 100,
  },
  textWhite: {
    color: "white"
  },
  partnersSection: {
    marginTop: 400
  }
}));
const Contact = () => {
  const classes = useStyles();

  return (
    <div>
      <SectionAlternate className={classes.mainHeader}>
        <SectionHeader
          title="Talk To Us"
          titleClasses={classes.textWhite}
          subtitle={
            <>
              <Grid item className={clsx(classes.marginBottom, classes.textWhite)}>
                We'd love to talk about how we can work together.
              </Grid>
              <ContactForm />
            </>
          }
        />
      </SectionAlternate>
      <SectionAlternate className={classes.partnersSection}>
        <ExperiencesSection data={experiences} />
      </SectionAlternate>
    </div>
  )
};

export default Contact;
