import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Image } from '../../../common/atoms';
import { SectionHeader } from '../../../common/molecules';
import { CardBase, CardReview } from '../../../common/organisms';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  cardBase: {
    '& .card-base__content': {
      padding: 0,
      height: 'auto',
    },
  },
  linkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.level2}`,
  },
  title: {
    fontWeight: 'bold',
  },
  innerPadding: {
    padding: '30px'
  }
}));

const CustomerCard = ({ themeMode = 'light', data, className, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} key={index} data-aos="fade-up">
            <a href={item.href} title={item.title}>
              <CardBase withShadow liftUp className={classes.cardBase}>
                <>
                  <Image
                    src={themeMode === 'light' ? item.cover : item.coverDark}
                    alt={item.title}
                  />
                  <div className={classes.innerPadding}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.title}
                    >
                      {item.subtitle}
                    </Typography>
                    <CardReview
                      key={index}
                      className={'swiper-slide'}
                      noBorder
                      noShadow
                      text={item.feedback}
                      authorName={item.authorName}
                      authorTitle={item.authorOccupation}
                      authorPhoto={item.authorPhoto}
                    />
                  </div>
                </>
              </CardBase>
            </a>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

CustomerCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  themeMode: PropTypes.string,
};

export default CustomerCard;
