import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Image } from '../../common/atoms';

const useStyles = makeStyles(() => ({
  promoLogo: {
    maxWidth: 120,
  },
  rootPadding: {
    padding: '30px'
  },
  noPadding: {
    padding: 0
  }
}));

const Partners = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.rootPadding} {...rest}>
      <Grid container justify="space-between">
        {data.map((partner, index) => (
          <Grid className={classes.noPadding} item container justify="center" xs={6} sm={2} key={index}>
            <Image
              src={partner.logo}
              alt={partner.name}
              className={classes.promoLogo}
              lazy={false}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Partners.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default Partners;
