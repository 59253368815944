import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const CustomerExperience = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Customer Experience"
      mainHeaderTitle="Customer Experience"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Creating World-Class Customer Experiences with ML"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy4/CaseStudy4.jpeg"}
      learnMoreLink="/case-studies/4"
    />
  )
}

export default CustomerExperience;