export const partners = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Partner1.png',
    style: {
      height: '42px',
      minWidth: '140px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Partner2.png',
    style: {
      height: '48px',
      marginTop: '-5px',
      maxwidth: '146px'
    }
  },
];