import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { useMediaQuery, Divider } from '@material-ui/core';
import Sidebar from '../components/Sidebar/Sidebar';
import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    useCases: {
      title: 'Use Cases',
      style: { 
        top: 5, 
        left: -60 
      },
      id: 'useCases',
      children: {
        menuItems: {
          groupTitle: 'Use Cases',
          pages: [
            {
              title: 'Image & Video Recognition',
              href: '/image-recognition',
              icon: "/assets/common/case-studies-images/vision_ai.svg"
            },
            {
              title: 'Forcasting',
              href: '/forcasting',
              icon: '/assets/common/case-studies-images/forcasting.svg',
            },
            {
              title: 'Product Recommendation',
              href: '/product-recommendation',
              icon: '/assets/common/case-studies-images/product_recommendation.svg',
            },
            {
              title: 'Customer Experience',
              href: '/customer-experience',
              icon: '/assets/common/case-studies-images/customer_analytics.svg',
            },
            {
              title: 'Fraud Detection',
              href: '/fraud-detection',
              icon: '/assets/common/case-studies-images/fraud_detection.svg',
            },
            {
              title: 'Process Automation',
              href: '/process-automation',
              icon: '/assets/common/case-studies-images/process_automation.svg',
            },
            {
              title: 'Predictive Maintenance',
              href: '/predictive-maintenance',
              icon: '/assets/common/case-studies-images/fraud_detection.svg',
            },
            {
              title: 'Sentiment Analysis',
              href: '/sentiment-analysis',
              icon: '/assets/common/case-studies-images/sentiment_analysis.svg',
            },
            {
              title: 'Cyber Security',
              href: '/cyber-security',
              icon: '/assets/common/case-studies-images/cyber_security.svg',
            },
            {
              title: 'Drug Discovery',
              href: '/drug-discovery',
              icon: '/assets/common/case-studies-images/drug_discovery.svg',
            },
            {
              title: 'Autonomous Driving',
              href: '/autonomous-driving',
              icon: '/assets/common/case-studies-images/autonomous_driving.svg',
            }
          ],
        },
      },
    },
    solutions: {
      title: 'Solutions',
      style: { 
        top: 5, 
        left: 145 
      },
      id: 'solutions',
      children: {
        menuItems: {
          groupTitle: 'Solutions',
          pages: [
            {
              title: 'Speech-to-Text',
              href: '/speech-to-text',
              icon: "/assets/pages/SpeechToText/SpeechToText_AI.svg",
            },
            {
              title: 'Vision AI',
              href: '/vision-ai',
              icon: "/assets/pages/VisionAI/Vision_AI.svg"
            },
            {
              title: 'Text-to-Speech',
              href: '/text-to-speech',
              icon: "/assets/pages/TextToSpeech/TextToSpeech.svg"
            },
            {
              title: 'Intelligent Translation',
              href: '/intelligent-translation',
              icon: "/assets/pages/Translation/Translation.svg"
            },
            {
              title: 'Natural Language Processing',
              href: '/natural-language',
              icon: "/assets/pages/NaturalLanguage/NaturalLanguage_AI.svg"
            },
            {
              title: 'Video AI',
              href: '/video-ai',
              icon: "/assets/pages/VideoAI/Video_AI.svg"
            },
            {
              title: 'Conversational AI',
              href: '/conversational-ai',
              icon: "/assets/pages/ConversationalAI/Conversational_AI.svg"
            },
            {
              title: 'Document AI',
              href: '/document-ai',
              icon: "/assets/pages/DocumentAI/Document_AI.svg"
            },
            {
              title: 'Intelligent Process Automation',
              href: '/intelligent-process-automation',
              icon: "/assets/pages/IntelligentProcessAutomation/Intelligent_AI.svg"
            }
          ],
        },
      },
    },
    pricing: {
      title: 'Pricing',
      id: 'pricing',
      children: {
        menuItems: {
          groupTitle: 'Pricing',
          pages: [
            {
              title: 'Pricing',
              href: '/pricing',
            },
          ],
        },
      },
    },
    resources: {
      title: 'Resources',
      style: { 
        top: 5, 
        left: -30
      },
      id: 'resources',
      children: {
        menuItems: {
          groupTitle: 'Resources',
          pages: [
            // {
            //   title: 'Resource Library',
            //   href: '/resource-library',
            // },
            {
              title: 'Blog',
              href: '/blog',
              icon: "/assets/pages/Blog/blog.svg"
            },
            // {
            //   title: 'Customers',
            //   href: '/customers',
            // },
            // {
            //   title: 'Events',
            //   href: '/events',
            // },
            // {
            //   title: 'Open Datasets',
            //   href: '/open-datasets',
            // },
            // {
            //   title: 'Interviews',
            //   href: '/interviews',
            // },
          ],
        },
      },
    },
    company: {
      title: 'Company',
      style: { 
        top: 5, 
        left: 105
      },
      id: 'company',
      children: {
        menuItems: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'About',
              href: '/about',
              icon: "/assets/pages/About/about.svg"
            },
            {
              title: 'Contact',
              href: '/contact',
              icon: "/assets/pages/Contact/contact.svg"
            },
            {
              title: 'Careers',
              href: '/careers',
              icon: "/assets/pages/Careers/careers.svg"
            }
          ],
        },
      }
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;
  const isLogin = window.location.pathname === '/login';

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
    {!isLogin &&
      <Header
        onSidebarOpen={handleSidebarOpen}
        pages={pages}
        themeMode={themeMode}
        themeToggler={themeToggler}
      />
    }
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
      </main>
      <Divider />
      {!isLogin &&
        <Footer pages={pages} />
      }
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        // cookieName="myAwesomeCookieName2"
        style={{ 
          background: theme.palette.background.gradient1,
          fontFamily: 'SharpSansNoOne-Medium'
        }}
        buttonStyle={{ 
          marginRight: 200, 
          color: "#ffffff", 
          fontSize: "13px", 
          background: theme.palette.alternate.template1
        }}
        expires={150}
      >
        This website uses cookies to improve your experience. See our <a style={{ color: theme.palette.alternate.template2 }} href="/privacy" target="_blank">Privacy Policy</a> to learn more.
      </CookieConsent>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
