import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Section, SectionAlternate } from '../../../common/organisms';
import {
  popularNews,
} from '../data/data';
import InterviewSection from '../../../components/InterviewSection/InterviewSection';
import { SectionHeader } from '../../../common/molecules';
import CustomButton from '../../../components/CustomButton/CustomButton';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  mainHeader: {
    background: 'black',
    height: 600,
  },
  mainHeaderTitle: {
    color: 'white',
    marginBottom: '20px',
    textAlign: 'left'
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  textGradient: {
    background: theme.palette.background.gradient1,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: "Arial, sans-serif",
    fontWeight: 'normal',
    // fontSize: '33px'
  }
}));

const Interviews = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <SectionAlternate className={classes.mainHeader}>
        <SectionHeader
          title='CAPSULA PRESENTS'
          titleVariant='h6'
          titleClasses={clsx(classes.mainHeaderTitle, classes.textGradient)}
          subtitle={
            <Grid>
              <Grid item container className={clsx(classes.mainHeaderTitle, classes.fontWeightBold)}>
                AI Futures
              </Grid>
              <Grid item container className={classes.mainHeaderTitle}>
                <Typography
                  variant="h5"
                  gutterBottom
                  align="center"
                >
                  Piecing together our future through stories from the frontlines of AI.
                </Typography>
              </Grid>
              <Grid item container>
                <CustomButton
                  variant="outlined"
                  size={isMd ? 'large' : 'medium'}
                  label="Talk To Us"
                  textColor="black"
                  bgColor="white"
                />
              </Grid>
            </Grid>
          }
          align={isMd ? 'center' : 'left'}
          subtitleVariant="h2"
        />
      </SectionAlternate>
      <Section>
        <InterviewSection data={popularNews} />
      </Section>
    </div>
  );
};

export default Interviews;