import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { colors } from '@material-ui/core';
import { SectionHeader } from '../../common/molecules';
import { LearnMoreLink } from '../../common/atoms';

const useStyles = makeStyles(theme => ({
  titleColor: {
    color: theme.palette.alternate.template2,
  },
}));

const Careers = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="CAREERS"
        titleVariant="h5"
        titleClasses={classes.titleColor}
        subtitle={
          <Grid flexDirection="column" justify="space-between">
            <Grid style={{ marginTop: 10 }}>
              <h2>Capsula is growing. Grow with us.</h2>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
              <p>Join us as we accelerate the development of AI applications.</p>
            </Grid>
          </Grid>
        }
        subtitleProps={{
          color: theme.palette.text.primary,
        }}
        data-aos="fade-up"
        align={isMd ? 'center' : 'left'}
        ctaGroup={[
          <LearnMoreLink title="Learn More" variant="h6" />
        ]}
      />
    </div>
  );
};

Careers.propTypes = {
  className: PropTypes.string,
};

export default Careers;
