import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage'
import Pricing from './pages/Pricing/Pricing'
import MainLayout from './layout/MainLayout'
import WithLayout from './WithLayout';
import Blog from './pages/Resources/Blog/Blog';
import Customers from './pages/Resources/Customers/Customers';
import Contact from './pages/Company/Contact/Contact';
import About from './pages/Company/About/About';
import Careers from './pages/Company/Careers/Careers';
import Interviews from './pages/Resources/Interviews/Interviews';
import OpenDatasets from './pages/Resources/OpenDatasets/OpenDatasets';
import Events from './pages/Resources/Events/Events';
import Singin from './pages/Signin/Signin';
import SpeechToText from './pages/Solutions/SpeechToText/SpeechToText';
import VisionAI from './pages/Solutions/VisionAI/VisionAI';
import TextToSpeech from './pages/Solutions/TextToSpeech/TextToSpeech';
import Translation from './pages/Solutions/Translation/Translation';
import NaturalLanguage from './pages/Solutions/NaturalLanguage/NaturalLanguage';
import DocumentAI from './pages/Solutions/DocumentAI/DocumentAI';
import ConversationalAI from './pages/Solutions/ConversationalAI/ConversationalAI';
import IntelligentProcessAutomation from './pages/Solutions/IntelligentProcessAutomation/IntelligentProcessAutomation';
import VideoAI from './pages/Solutions/VideoAI/VideoAI';
import Privacy from './pages/SitePages/Privacy';
import Imprint from './pages/SitePages/Imprint';
import ImageAndVideoRecognition from './pages/UseCases/ImageAndVideoRecognition/ImageAndVideoRecognition';
import Forcasting from './pages/UseCases/Forcasting/Forcasting';
import ProductRecommendation from './pages/UseCases/ProductRecommendation/ProductRecommendation';
import CustomerExperience from './pages/UseCases/CustomerExperience/CustomerExperience';
import FraudDetection from './pages/UseCases/FraudDetection/FraudDetection';
import ProcessAutomation from './pages/UseCases/ProcessAutomation/ProcessAutomation';
import PredictiveMaintenance from './pages/UseCases/PredictiveMaintenance/PredictiveMaintenance';
import SentimentAnalysis from './pages/UseCases/SentimentAnalysis/SentimentAnalysis';
import CyberSecurity from './pages/UseCases/CyberSecurity/CyberSecurity';
import DrugDiscovery from './pages/UseCases/DrugDiscovery/DrugDiscovery';
import AutonomousDriving from './pages/UseCases/AutonomousDriving/AutonomousDriving';
import BlogArticle from './pages/Resources/Blog/BlogArticle';

const Routes = () => {
  // const routes = [
  //   { component: LandingPage, path: "/" },
  //   { component: Pricing, path: "/pricing" },
  //   { component: Blog, path: "/blog" },
  //   { component: Customers, path: "/customers" },
  //   { component: Contact, path: "/ontact" },
  //   { component: About, path: "/about" },
  //   { component: Careers, path: "/careers" },
  //   { component: Interviews, path: "/interviews" },
  //   { component: OpenDatasets, path: "/open-datasets" },
  //   { component: Events, path: "/events" },
  //   { component: Singin, path: "/login" },
  //   { component: SpeechToText, path: "/speech-to-text" },
  //   { component: VisionAI, path: "/vision-ai" },
  // ];

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={LandingPage}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Singin}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/pricing"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Pricing}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/blog"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Blog}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/customers"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Customers}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/contact"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Contact}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/about"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={About}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/careers"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Careers}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/interviews"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Interviews}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/open-datasets"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={OpenDatasets}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/events"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Events}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/speech-to-text"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={SpeechToText}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/vision-ai"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={VisionAI}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/text-to-speech"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={TextToSpeech}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/intelligent-translation"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Translation}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/natural-language"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={NaturalLanguage}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/document-ai"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={DocumentAI}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/video-ai"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={VideoAI}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/conversational-ai"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={ConversationalAI}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/intelligent-process-automation"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={IntelligentProcessAutomation}
            layout={MainLayout}
          />
        )}
      />
      {/* UseCases Start*/}
      <Route
        exact
        path="/image-recognition"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={ImageAndVideoRecognition}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/forcasting"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Forcasting}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/product-recommendation"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={ProductRecommendation}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/customer-experience"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={CustomerExperience}
            layout={MainLayout}
          />
        )}
      />
      {/* <Route
        exact
        path="/fraud-detection"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={FraudDetection}
            layout={MainLayout}
          />
        )}
      /> */}
      <Route
        exact
        path="/process-automation"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={ProcessAutomation}
            layout={MainLayout}
          />
        )}
      />
      {/* <Route
        exact
        path="/predictive-maintenance"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={PredictiveMaintenance}
            layout={MainLayout}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/sentiment-analysis"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={SentimentAnalysis}
            layout={MainLayout}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/cyber-security"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={CyberSecurity}
            layout={MainLayout}
          />
        )}
      /> */}
      <Route
        exact
        path="/drug-discovery"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={DrugDiscovery}
            layout={MainLayout}
          />
        )}
      />
      {/* <Route
        exact
        path="/autonomous-driving"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={AutonomousDriving}
            layout={MainLayout}
          />
        )}
      /> */}
      {/* UseCases End */}
      <Route
        exact
        path="/privacy"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Privacy}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/imprint"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={Imprint}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/case-studies/:id"
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={BlogArticle}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        render={matchProps => (
          <WithLayout
            {...matchProps}
            component={LandingPage}
            layout={MainLayout}
          />
        )}
      />
    </Switch>
  )
}

export default Routes;