export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Read documents`,
    subtitle:
      'Our OCR (optical character recognition) and form parser technology uses industry-leading deep-learning neural network algorithms to perform text, character, and image recognition in over 200 languages with exceptional accuracy.',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Understand documents',
    subtitle:
      'Using the same deep machine learning technology that powers big Search Engines, Natural Language Processing enable you to derive valuable insights from your unstructured documents.',
  },
  // {
  //   icon: 'far fa-heart',
  //   title: 'Reduce acquisition rubbing',
  //   subtitle:
  //     'With us, stores can produce an appealing mobile experience that allows your consumers to submit a photo of an item and also quickly see a checklist of similar products for purchase from you.',
  // },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Make faster decisions using data from your documents',
    description:
      "Improve operational efficiency by extracting structured data from unstructured documents and making that available to your business apps and users.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Ensure your data is accurate and compliant',
    description:
      'Automate and validate all your documents to streamline compliance workflows, reduce guesswork, and keep data accurate and compliant.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Utilize our data labeling solution',
    description:
    "If you have photos that aren't yet identified, we have a group of people that can assist you annotate photos, video clips, and message to get high-quality training information.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];