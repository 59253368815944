import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const TextToSpeech = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Text To Speech"
      mainHeaderTitle="Text To Speech"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Convert message into natural-sounding speech using our API powered AI innovations."
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/TextToSpeech/TextToSpeech.svg"}
      solutionsSectionSubtitle="We provide two computer vision products that use machine discovering to assist you understand your images with industry-leading forecast precision."
    />
  )
}

export default TextToSpeech;
