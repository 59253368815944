import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Partners from '../../components/Partners/Partners';
import ExperiencesSection from '../../components/ExperiencesSection/ExperiencesSection';
import Integrations from '../../components/Integrations/Integrations';
import { CardBase, Section, SectionAlternate } from '../../common/organisms';
import { experiences, partners, integrations, promoNumbers, awards, ourWork } from './data/data';
import VideoSection from '../../components/VideoSection/VideoSection';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import Datasets from '../../components/Datasets/Datasets';
// import Testimonials from '../../components/Testimonials/Testimonials';
import Hero from '../../components/Hero/Hero';
// import Careers from '../../components/Careers/Careers';
import GetStartedSection from '../../components/GetStartedSection/GetStartedSection';
import { Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import ProcessSection from '../../components/ProcessSection/ProcessSection';
import PartnersSection from '../../components/PartnersSection/PartnersSection';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  bottomPadding: {
    paddingBottom: '100px'
  },
  sectionNoPadding: {
    padding: 0
  },
  textWhite: {
    color: 'white'
  },
  fontWeightBold: {
    fontWeight: 'bold'
  },
  marginTop: {
    marginTop: 20
  },
  noMargin: {
    marginTop: "-6px"
  }
}));

const LandingPage = ({ themeMode }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <LandingHeader />
      <ExperiencesSection className={classes.noMargin} data={experiences} />
      <Section>
        <Integrations data={integrations} />
      </Section>
      <SectionAlternate className={classes.pagePaddingTop}>
        <Typography variant="h3" align="center" style={{ marginBottom: '30px' }}>What we do for you?</Typography>
        <VideoSection data={ourWork} />
      </SectionAlternate>
      {/* <Hero
        title="Our Process, Your Results"
        subtitle={
          <CardBase className={classes.marginTop} withShadow liftUp>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  gutterBottom
                  className={classes.fontWeightBold}
                  noWrap
                >
                  Executive Briefing
                </Typography>
                <Typography variant="body1" color="textPrimary" noWrap>
                  2 Hours
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  gutterBottom
                  className={classes.fontWeightBold}
                  noWrap
                >
                  Technology Assessment
                </Typography>
                <Typography variant="body1" color="textPrimary" noWrap>
                  1-2 Days
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  gutterBottom
                  className={classes.fontWeightBold}
                  noWrap
                >
                  Implementation of Solution
                </Typography>
                <Typography variant="body1" color="textPrimary" noWrap>
                  8-14 Weeks
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  gutterBottom
                  className={classes.fontWeightBold}
                  noWrap
                >
                  Production
                </Typography>
                <Typography variant="body1" color="textPrimary" noWrap>
                  3-6 Months
                </Typography>
              </Grid>
            </Grid>
          </CardBase>
        }
        themeMode={themeMode}
      /> */}
      <ProcessSection
        themeMode={themeMode}
      />
      <Section>
        <Datasets data={promoNumbers} />
      </Section>
      <SectionAlternate className={classes.sectionNoPaddingTop}>
        <Section className={classes.sectionNoPadding}>
          <Typography variant="h4" align="center" style={{ marginBottom: 40 }}>
            Awards & Certifications
          </Typography>
          <Partners data={awards} />
        </Section>
        {/* <Section className={classes.sectionNoPadding}>
          <Partners data={experiences} />
        </Section>
        <Section className={classes.sectionNoPadding}>
          <Partners data={experiences} />
        </Section> */}
      </SectionAlternate>
      {/* <Section>
        <Testimonials data={reviews} />
      </Section>
      <SectionAlternate>
        <Careers />
      </SectionAlternate> */}
      <Section narrow>
        <GetStartedSection />
      </Section>
      <PartnersSection data={partners} />
    </div>
  );
};

export default LandingPage;
