import { colors } from '@material-ui/core';

export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const partners = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Partner1.png',
    style: {
      height: '42px',
      minWidth: '140px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Partner2.png',
    style: {
      height: '60px',
      marginTop: '-16px',
      maxwidth: '146px'
    }
  },
];

export const awards = [
  {
    name: 'Airbnb',
    logo: '/assets/common/awards/Logo1final.png',
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/awards/Logo2final.png',
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/awards/Logo3final.png',
  },
  {
    name: 'Instagram',
    logo: '/assets/common/awards/Logo4final.png',
  }
];

export const integrations = [
  {
    logo: '/assets/common/case-studies/Vision_AI.svg',
    name: 'Vision AI',
    title:
      "Derive insights from your images with AutoML Vision or use pre-trained Vision API models to detect emotion, objects, understand text, and more.",
    link: '/vision-ai'
  },
  {
    logo: '/assets/common/case-studies/Document_AI.svg',
    name: 'Document AI',
    title:
      'Unlock insights from documents with machine learning. Make better decisions using document data and make it available to your applications and users.',
    link: '/document-ai'
  },
  {
    logo: '/assets/common/case-studies/Image_AI.svg',
    name: 'Intelligent Process Automation',
    title:
      'Intelligent Process Automation (IPA) is technology that enables organizations to automate processes that involve structured, semi-structured, and unstructured formats including forms, documents, text, images, video and more.',
    link: '/intelligent-process-automation'
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith 2',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const promoNumbers = [
  {
    color: colors.blue,
    icon: '/assets/common/case-studies/Vision_AI.svg',
    title: 'Image & Video Recognition',
    // subtitle: '',
    description: 'How Disney is revolutionizing search through AI',
    link: '/image-recognition'
  },
  {
    color: colors.purple,
    icon: '/assets/common/case-studies-images/forcasting.svg',
    title: 'Forecasting',
    // subtitle: 'Open Datasets',
    description: "How BuildFax leverages Machine Learning for Predictions",
    link: '/forcasting'
  },
  {
    color: colors.pink,
    icon: '/assets/common/case-studies-images/customer_analytics.svg',
    title: 'Customer Analytics',
    // subtitle: 'Open Datasets',
    description: 'Creating World-Class Customer Experiences with ML',
    link: '/customer-experience'
  },
  {
    color: colors.indigo,
    icon: '/assets/common/case-studies-images/process_automation.svg',
    title: 'Process Automation',
    // subtitle: 'Open Datasets',
    description: 'Intelligent Process Automation applied to Insurance Claims',
    link: '/process-automation'
  },
];

export const ourWork = [
  {
    description:
      "Capsula.ai enhances processes. Uncovers effectiveness and also offers insights, making the path to artificial intelligence powered applications smooth as well as economical. It leverages existing systems to maximize value through technology.",
  },
  {
    description:
      "Whether structured or unstructured data, Capsula.ai is able to consume any text-based data without requiring you to organize it first. Consume data from anywhere-- whether it's on-premises or in the cloud, from IoT gadgets to your point-of-sale, CRM and past. We utilize advanced ML/AI to obtain actionable insights fast and accurate.",
  },
  {
    description:
      "By injecting AI into your systems, your teams are totally free to concentrate on work with far better returns and value. We shift them from low-value tasks to high-value tasks.",
  },
  {
    description:
      "Capsula.ai makes it effortless to create large-scale customizable deep learning systems. Our systems generate precise predictions which can be integrated into all aspects of your customer experience and business processes.",
  },
];