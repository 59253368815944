import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, colors, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { IconAlternate, SectionHeader, TypedText } from '../../../common/molecules';
import { Section, SectionAlternate } from '../../../common/organisms';
import TwoItemsGrid from '../../../components/TwoItemsGrid/TwoItemsGrid';
import Video from '../../../components/Video/Video';
import Team from '../../../components/Team/Team';
import CompanyInfo from '../../../components/CompanyInfo/CompanyInfo';
import Hero from '../../../components/Hero/Hero';
import Benefits from '../../../components/Benefits/Benefits';
import { companyInfo, jobs } from '../data/data';
import Slider from '../../../components/Slider/Slider';
import CareerSwiper from '../../../components/CareerSwiper/CareerSwiper';
import GetStartedSection from '../../../components/GetStartedSection/GetStartedSection';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { team } from '../About/data/data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  madcapSection: {
    backgroundColor: '#0000008a',
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  },
}));

const Careers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const leftItem = (
    <SectionHeader
      title={
        <span>
          Launch Your Website{' '}
          <Typography component="span" variant="inherit" color="primary">
            Marketing Platform
          </Typography>
        </span>
      }
      subtitle="We help digital agencies, local business and managed service providers to have the best Website Marketing service."
      ctaGroup={[
        <Button
          variant="outlined"
          color="primary"
          size={isMd ? 'large' : 'medium'}
        >
          Launch a video
      </Button>,
      ]}
      align={isMd ? 'left' : 'center'}
    />
  );

  const ourMissionLeft = (
    <SectionHeader
      label="make things done"
      title={
        <>
          <span>
            The most useful resource
                    <br />
            <Typography color="secondary" variant="inherit" component="span">ever created</Typography>
            <br />
            <Typography color="secondary" variant="inherit" component="span">for&nbsp;</Typography>
          </span>
          <TypedText
            component="span"
            variant="h4"
            color="secondary"
            className={classes.typed}
            typedProps={{
              strings: ['web developers.', 'fouders.', 'designers.'],
              typeSpeed: 50,
              loop: true,
            }}
          />
        </>
      }
      subtitle="Using TheFront to build your site means never worrying about designing another page or cross browser compatibility. Our ever-growing library of components and pre-designed layouts will make your life easier."
      align="left"
      disableGutter
    />
  );

  const weIncludeSectionLeft = (
    <SectionHeader
      label="We include and empower."
      subtitle="At Capsula, we believe each of us is responsible for creating an environment where people are heard, valued, and equally successful, and that is reflected in our every decision about our company and products. We include and empower each day because we know that we’re stronger when we provide opportunities for everyone to grow and succeed together."
      align="left"
      disableGutter
    />
  );

  const weIncludeSectionRight = (
    <SectionHeader
      label="We hold ourselves accountable."
      subtitle="We recognize the first step in creating a more equitable workplace is to understand where we are on the journey. We aim to be as transparent as possible and hold ourselves accountable for creating a more inclusive and equitable workplace where everyone can learn, grow and thrive."
      align="left"
      disableGutter
    />
  );

  const careersSection2Left = (
    <SectionHeader
      // label="We include and empower."
      subtitle="We believe that inclusivity begins with the candidate experience. We are intentional in how we engage with diverse communities and are improving our recruiting process to lift barriers and remove bias at all stages of the candidate experience."
      align="left"
      disableGutter
    />
  );

  const careersSection2Right = (
    <SectionHeader
      // label="We hold ourselves accountable."
      subtitle="We are planning to expand our university recruitment program to focus on more diverse institutions, including historically black colleges and universities."
      align="left"
      disableGutter
    />
  );

  return (
    <div className={classes.root}>
      <Section>
        <SectionHeader
          title="Equity & Belonging"
          titleVariant="h2"
          subtitle="We’re stronger together. We believe that everyone should live and work in a world where they feel valued and supported … and like they belong."
          subtitleVariant="h4"
          ctaGroup={[(
            <CustomButton variant="contained" size="large" label="Open Positions" href="/contact" />
          )]}
        />
      </Section>
      <Hero 
        title="Capsula is growing. Grow with us."
        subtitle="Take the next step."
      />
      <Section>
        <SectionHeader
          title="We include and empower."
          titleVariant="h3"
          subtitle="We’re committed to recruiting for diversity."
        />
      </Section>
      <Section>
        <TwoItemsGrid leftItem={weIncludeSectionLeft} rightItem={weIncludeSectionRight} />
      </Section>
      {/* <Section>
        <Video />
      </Section> */}
      {/* <Section>
        <TwoItemsGrid leftItem={leftItem} />
      </Section> */}
      <SectionAlternate>
        <TwoItemsGrid leftItem={careersSection2Left} rightItem={careersSection2Right} />
      </SectionAlternate>
      {/* <Section>
        <CompanyInfo data={companyInfo} />
      </Section> */}
      {/* <SectionAlternate>
        <Slider data={jobs} />
      </SectionAlternate> */}
      {/* <Section>
        <TwoItemsGrid rightItem={leftItem} />
      </Section> */}
      {/* <SectionAlternate>
        <Team data={team} />
      </SectionAlternate> */}
      {/* <Section>
        <Benefits />
      </Section>
      <CareerSwiper data={jobs} /> */}
      <Section narrow>
        <GetStartedSection />
      </Section>
    </div>
  );
};

export default Careers;
