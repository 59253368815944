export const articles3 = [
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy1/CaseStudy1.jpg',
      srcSet: '/assets/common/case-studies-images/CaseStudy1/CaseStudy1.jpg',
    },
    title: 'Disney: How Disney is revolutionizing search through AI',
    subtitle:
      "Disney's substantial archive spans the program of nearly a century of material, which can transform any kind of look for details characters, scenes or on-screen items within it right into a significant task. Nonetheless, a team of scientists from Disney's Direct-to-Consumer & International Company (DTCI) have actually developed a machine discovering platform to aid automate the electronic archival of all that material. They call it the Content Genome.",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/1'
  },
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy2/CaseStudy2.jpeg',
      srcSet: '/assets/common/case-studies-images/CaseStudy2/CaseStudy2.jpeg',
    },
    title: 'Forecasting: How BuildFax leverages Machine Learning for Predictions',
    subtitle:
      "BuildFax began in 2008 by accumulating dispersed building allow information from throughout the USA as well as supplying it to various other organizations, such as insurance companies, developing examiners, and also economic experts. Today it provides solutions customized to those careers along with a variety of services, including indices that track patterns like housing remodels as well as new business construction. The business is based in Asheville, N.C",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/2'
  },
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy3/CaseStudy3.png',
      srcSet: '/assets/common/case-studies-images/CaseStudy3/CaseStudy3.png',
    },
    title: 'Product Recommendation: Pomelo Increases Revenue for Fashion Shoppers with Personalization',
    subtitle:
      "Pomelo Fashion, a worldwide fashion e-commerce solution based in Southeast Asia, had been displaying things on its web site in much the same means considering that it was founded in 2013. The setup had expanded stagnant, as well as that the formula for displaying the things count on old information streams with limited inputs and erratic precision. So as a fast-growing, ingenious startup, Pomelo Fashion set out to create individualized consumer experiences that would certainly boost the discoverability of new products and rise earnings-- and it required an option that would certainly do so at scale.",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/3'
  },
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy4/CaseStudy4.jpeg',
      srcSet: '/assets/common/case-studies-images/CaseStudy4/CaseStudy4.jpeg',
    },
    title: 'Customer Analytics: Creating World-Class Customer Experiences with ML',
    subtitle:
      "Constructing a relied on ecommerce brand name calls for a focus on customer experience technology. Particularly in the apparel sector, traditional, hands-on retail experience has shaped customer assumptions. In words of Ameen Kazerouni, head of artificial intelligence study and also platforms at Zappos, The holy grail of ecommerce is to get consumers to really feel as comfy as they would in a store-- knowing exactly how an item fits, how it looks, with as much self-confidence as if they were physically trying it on.",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/4'
  },
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy5/CaseStudy5.jpeg',
      srcSet: '/assets/common/case-studies-images/CaseStudy5/CaseStudy5.jpeg',
    },
    title: 'Process Automation: Intelligent Process Automation applied to Insurance Claims',
    subtitle:
      "Intelligent machines that work beyond standard automation as well as take advantage of core AI abilities to learn from its human managers about just how to make use of info accumulated.",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/5'
  },
  {
    cover: {
      src: '/assets/common/case-studies-images/CaseStudy6/CaseStudy6.jpeg',
      srcSet: '/assets/common/case-studies-images/CaseStudy6/CaseStudy6.jpeg',
    },
    title: 'Drug Discovery: New Drug Discovery through using Artificial Intelligence',
    subtitle:
      "Drug Discovery is the very first step of the value chain that determines brand-new candidate rehabs for treating or healing human illness. It is the first phase of biopharma R&D and also includes the recognition and optimization of potential brand-new medications and also a preclinical in vivo recognition via cell assays and animal designs. Effective prospects that meet the regulatory needs used to drug discovery move into the scientific trial phase, where they are checked for efficacy and safety in people.",
    date: 'Feb 27, 2021',
    category: 'Case Study',
    link: '/case-studies/6'
  }
];