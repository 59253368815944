import React from 'react';
import PropTypes from 'prop-types';
import BackgroundVideo from '../BackgroundVideo/BackgroundVideo';

const LandingHeader = props => {
  const { className, ...rest } = props;
  const videoSource = "https://capsula-media.s3-eu-west-1.amazonaws.com/videos/home_page_video.mp4";

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <BackgroundVideo
        src={videoSource}
        title="Solving the Unsolvable"
        subtitle="The future lies in an evolving partnership of Human and AI"
      />
    </div>
  );
};

LandingHeader.propTypes = {
  className: PropTypes.string
};

export default LandingHeader;