import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const IntelligentProcessAutomation = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Intelligent Process Automation"
      mainHeaderTitle="Intelligent Process Automation"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Intelligent Process Automation (IPA) is technology that enables organizations to automate processes that involve structured, semi-structured, and unstructured formats including forms, documents, text, images, video and more."
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/IntelligentProcessAutomation/Intelligent_AI.svg"}
      solutionsSectionSubtitle="We provide two computer vision products that use machine discovering to assist you understand your images with industry-leading forecast precision."
      hideUseCases
    />
  )
}

export default IntelligentProcessAutomation;
