/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Typography,
  ListItemIcon,
  Divider,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Image } from '../../../common/atoms';

const useStyles = makeStyles(theme => ({
  root: {
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
  },
  menuItemIcon: {
    width: 35,
    marginRight: 15
  }
}));

const SidebarNav = props => {
  const { pages, onClose, className, ...rest } = props;
  const classes = useStyles();

  const { useCases, solutions, pricing, resources, company } = pages;

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Image
              src={page.icon}
              className={classes.menuItemIcon}
            />
            <Typography
              variant="body2"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              color="textPrimary"
              onClick={() => onClose()}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const LandingPages = () => {
    const { menuItems } = useCases.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  const SolutionsPages = () => {
    const { menuItems } = solutions.children;
    const menuItems1 = {
      pages: menuItems.pages.slice(0, 5)
    };
    const menuItems2 = {
      pages: menuItems.pages.slice(5, 9)
    };

    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems1} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems2} />
        </div>
      </div>
    );
  };

  const PricingPages = () => {
    const { menuItems } = pricing.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  const ResourcePages = () => {
    const { menuItems } = resources.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>

      </div>
    );
  };

  const CompanyPages = () => {
    const { menuItems } = company.children;

    const morePages = [{
      title: 'Terms & Privacy',
      href: '/privacy',
      icon: "/assets/pages/Privacy/Privacy.svg"
    },
    {
      title: 'Imprint',
      href: '/imprint',
      icon: "/assets/pages/Imprint/Imprint.svg"
    }];

    const additionalPages = {
      groupTitle: menuItems.groupTitle,
      pages: [...menuItems.pages, ...morePages]
    };

    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={additionalPages} />
        </div>

      </div>
    );
  };

  const footerPages = {
    findUs: {
      title: 'Find Us',
      children: {
        menuItems: {
          groupTitle: 'Find Us',
          pages: [
            {
              title: 'LinkedIn',
              href: 'https://www.linkedin.com/company/capsulaai',
              icon: "/assets/pages/Linkedin/Linkedin.svg"
            }
          ],
        },
      },
    },
    contact: {
      title: 'Contact',
      children: {
        menuItems: {
          groupTitle: 'Contact',
          pages: [
            {
              title: 'info@capsula.ai',
              href: 'mailto:info@capsula.ai',
              icon: "/assets/pages/Email/Email.svg"
            },
          ],
        },
      },
    },
  };

  const FindUsPages = () => {
    const { menuItems } = footerPages.findUs.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  const ContactFooterMenu = () => {
    const { menuItems } = footerPages.contact.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={() => onClose()}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Use Cases
        </Typography>
        <LandingPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Solutions
        </Typography>
        <SolutionsPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Pricing
        </Typography>
        <PricingPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Resources
        </Typography>
        <ResourcePages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Company
        </Typography>
        <CompanyPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Find Us
        </Typography>
        <FindUsPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Contact
        </Typography>
        <ContactFooterMenu />
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
