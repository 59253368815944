export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Discover things automatically`,
    subtitle:
      'Spot as well as categorize numerous things including the area of each item within the picture. Find out more about item discovery',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Gain knowledge at the edge',
    subtitle:
      'Construct as well as deploy quickly, high-accuracy versions to identify pictures or discover objects at the side, and also activate real-time actions based on data. Our technology sustains a selection of side devices where sources are constrained as well as latency is vital.',
  },
  {
    icon: 'far fa-heart',
    title: 'Reduce acquisition rubbing',
    subtitle:
      'With us, stores can produce an appealing mobile experience that allows your consumers to submit a photo of an item and also quickly see a checklist of similar products for purchase from you.',
  },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'IPA makes sense of unstructured data',
    description:
      "This is where intelligent process automation (IPA) shines. Built for unstructured content, these systems use features like optical character recognition (OCR) to extract data from non-traditional sources like text, documents, and images—and they’re smart enough to transform the data into structured content that RPA can handle. This makes intelligent process automation the perfect complement to RPA. Any organization using robotic process automation can significantly extend operational efficiency and agility by augmenting workflows with intelligence.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Intelligent Process Automation learns and improves from experience',
    description:
      'While most workflow solutions lean heavily on humans to dictate appropriate actions, intelligent process automation can act autonomously and gets better over time. To do so, IPA leverages machine learning algorithms that use pattern recognition—instead of hardcoded rules—to make process decisions. This is in stark contrast to less intelligent options that can only do what they’re told. When limited to a one-size-fits-all approach, the most well-intentioned automation can cause workflow hiccups that require human help. For example, an appointment scheduling tool without machine learning is likely to double-book meetings unless a human intervenes. But an intelligent appointment scheduling tool will “know” not to double-book based on existing data—in this case, the presence of another meeting. With real-time analytics and decision automation, IPA can spot patterns, identify anomalies, make effective judgments, and take appropriate action.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'IPA improves the customer and employee experience',
    description:
    "Intelligent process automation may excel at handling dynamic or uncertain workflows, but it’s also an effective way to streamline operations and improve the customer experience. Take onboarding for example. With IPA, multi-branch businesses like banks and financial institutions can capture relevant unstructured data from onboarding documents to push into the company’s main management system. Armed with such data, these businesses can onboard clients faster—and more accurately—resulting in happier customers and shorter time-to-revenue. This level of automation proves especially valuable in healthcare where workers typically contend with fragmented medical records and one-too-many manual workflows. With IPA, healthcare organizations can automate important processes, including the identification of critical conditions with analytics. By operationalizing information at scale, intelligent process automation creates order and efficiency where there could be chaos.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Process Automation reduces operational costs + drives revenue',
    description:
    "Many automation solutions suffer from a lack of situational awareness. This usually shows up as the need for human intervention when something doesn’t work as planned. For instance, a traditional workflow automation system may reject a new bank customer’s e-form if they fail to complete it properly. But an IPA system will use its smarts to identify and self-correct form errors without involving employees.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];