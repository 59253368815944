import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Popover,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Image } from '../../common/atoms';
import { useRef } from 'react';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    color: 'black',
    marginBottom: 5,
    '&:hover': {
      color: theme.palette.alternate.template1,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark, 
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.alternate.template2,
    },
  },
  listItemButton: {
    whiteSpace: 'nowrap',
    color: 'black'
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    border: theme.spacing(2),
    // boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    pointerEvents: "auto",
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 100,
    height: 32,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    padding: '20px',
    paddingBottom: '5px',
    '&:last-child': {
      marginRight: 0,
    },
    color: 'black !important',
    '&:hover': {
      color: '#3700b3 !important'
    }
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  menuItemIcon: {
    width: 35,
    marginRight: 15
  }
}));

const Header = ({ history, themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const popoverAnchor = useRef(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const { useCases, solutions, pricing, resources, company } = pages;

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Image
              src={page.icon}
              className={classes.menuItemIcon}
            />
            <Typography
              variant="body1"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              onClick={handleClose}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const LandingPages = () => {
    const { menuItems } = useCases.children;
    const menuItems1 = {
      pages: menuItems.pages.slice(0, 6)
    };
    const menuItems2 = {
      pages: menuItems.pages.slice(6, 11)
    };
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems1} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems2} />
        </div>
      </div>
    );
  };

  const SolutionsPages = () => {
    const { menuItems } = solutions.children;
    const menuItems1 = {
      pages: menuItems.pages.slice(0, 5)
    };
    const menuItems2 = {
      pages: menuItems.pages.slice(5, 9)
    };

    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems1} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems2} />
        </div>
      </div>
    );
  };

  const PricingPages = () => {
    const { menuItems: { pages } } = pricing.children;
    return (
      <div className={classes.menu}>
        <a className={classes.menuItem} href={pages[0].href}>
          {pages[0].title}
        </a>
      </div>
    );
  };

  const ResourcePages = () => {
    const { menuItems } = resources.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>

      </div>
    );
  };

  const CompanyPages = () => {
    const { menuItems } = company.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={menuItems} />
        </div>

      </div>
    );
  };

  const renderPages = id => {
    if (id === 'useCases') {
      return <LandingPages />;
    }
    if (id === 'solutions') {
      return <SolutionsPages />;
    }
    if (id === 'pricing') {
      return <PricingPages />;
    }
    if (id === 'resources') {
      return <ResourcePages />;
    }
    if (id === 'company') {
      return <CompanyPages />;
    }
  };

  // const open = Boolean(anchorEl);

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="Capsula">
          <Image
            className={classes.logoImage}
            src={'../../assets/images/CapsulaNamedLogo.png'}
            alt="Capsula"
            lazy={false}
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>
          {[useCases, solutions, resources, company].map((page) => (
            <div key={page.id}>
              <ListItem
                ref={popoverAnchor}
              >
                <Typography
                  variant="body1"
                  aria-owns={openedPopoverId === page.id ? page.id : undefined}
                  aria-haspopup="true"
                  aria-describedby={page.id}
                  onMouseEnter={e => handleClick(e, page.id)}
                  onMouseLeave={handleClose}
                  className={clsx(classes.listItemText, 'menu-item')}
                >
                  {page.title}
                </Typography>
              </ListItem>
              <Popover
                style={page.style}
                id={page.id}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={openedPopoverId === page.id}
                // anchorEl={anchorEl}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                // onClose={handleClose}
                disableRestoreFocus
                PaperProps={{ onMouseEnter: e => handleClick(e, page.id), onMouseLeave: handleClose }}
              >
                <div>{renderPages(page.id)}</div>
              </Popover>
            </div>
          ))}
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Typography
              variant="body1"
              color="black"
              component="a"
              target="blank"
              href="/login"
              className={classes.listItemButton}
            >
              Sign In
              </Typography>
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Header.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default Header;
