import React from 'react';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { SectionHeader } from '../../../common/molecules';
import { Section, SectionAlternate } from '../../../common/organisms';
import { customers } from './data/data';
import CustomerCard from '../../../components/Cards/CustomerCard/CustomerCard';
import Partners from '../../../components/Partners/Partners';
import { experiences } from '../../LandingPage/data/data';
import GetStartedSection from '../../../components/GetStartedSection/GetStartedSection';
import CustomButton from '../../../components/CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.gradient7
  },
  normalFontWeight: {
    fontWeight: 'normal'
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

const Customers = ({ themeMode }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  return (
    <div>
      <SectionAlternate className={classes.root}>
        <SectionHeader
          title="Customers"
          subtitle="Scale's Platform and products were developed by ML engineers for ML engineers to deliver large volumes of unbiased, highly accurate training data at speed."
          align={isMd ? 'center': 'left'}
          titleVariant="h4"
          titleProps={{ className: classes.normalFontWeight }}
          subtitleProps={{ className: classes.normalFontWeight, color: 'black' }}
          ctaGroup={[
            <CustomButton
              size="large"
              variant="contained"
              label="Talk To Us"
            />
          ]}
        />
        <CustomerCard data={customers} themeMode={themeMode} />
      </SectionAlternate>
      <SectionAlternate className={classes.sectionNoPaddingTop}>
        <Partners data={experiences} />
        <Partners data={experiences} />
        <Partners data={experiences} />
      </SectionAlternate>
      <Section narrow>
        <GetStartedSection />
      </Section>
    </div>
  );
}

export default Customers;
