export const pricings = [
  {
    title: 'Enterprise',
    subtitle: 'A pay-once license, just for you',
    monthly: 49,
    annual: 480,
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  }
];