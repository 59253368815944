export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Video Intelligence`,
    subtitle:
      'Video Intelligence has a graphical interface that makes it easy to train your own custom models to classify and track objects within videos, even if you have minimal machine learning experience. It’s ideal for projects that require custom labels that aren’t covered by the pre-trained Video Intelligence API.',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Video Intelligence API',
    subtitle:
      'Our API has pre-trained machine learning models that automatically recognize a vast number of objects, places, and actions in stored and streaming video. Offering exceptional quality out of the box, it’s highly efficient for common use cases and improves over time as new concepts are introduced.',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Content Moderation',
    subtitle:
      'Identify when inappropriate content is being shown in a given video. You can instantly conduct content moderation across petabytes of data and more quickly and efficiently filter your content or user-generated content.',
  },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Precise video analysis',
    description:
      "We utilize Optical Character Recognition to find text within pictures in more than 50 languages and also different data types. It's also part of Paper AI, which lets you process millions of records promptly and also automate company process.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Simplify media management',
    description:
      'Find value in vast archives by making media easily searchable and discoverable. Easily search your video catalog the same way you search text documents. Extract metadata that can be used to index, organize, and search your video content, as well as control and filter content for what’s most relevant.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Easily create intelligent video apps',
    description:
    "Gain insights from video in near real time using the streaming video application and trigger events based on objects detected. Build engaging customer experiences with highlight reels, recommendations, interactive videos, and more.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Automate expensive workflows',
    description:
    "Gain insights from video in near real time using the streaming video application and trigger events based on objects detected. Build engaging customer experiences with highlight reels, recommendations, interactive videos, and more.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Easily create intelligent video apps',
    description:
    "Reduce time and costs associated with transcribing videos and generating closed captions, as well as flagging and filtering inappropriate content.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];