export const caseStudy1 = {
  // headline:
  quote: "",
  text: [
    "Disney's substantial archive spans the program of nearly a century of material, which can transform any kind of look for details characters, scenes or on-screen items within it right into a significant task. Nonetheless, a team of scientists from Disney's Direct-to-Consumer & International Company (DTCI) have actually developed a machine discovering platform to aid automate the electronic archival of all that material. They call it the Content Genome.",
    "Well prior to various other animation studios, Disney firmly insisted that its archive come to writers as well as illustrators that may need it for reference or motivation. Drawings, principle artwork, and also a lot more from favorites like Dumbo as well as Peter Pan were thoroughly concealed in this safe. And in the years because, Disney has actually remained devoted to preservation.",
    "With virtually a century of content on its hands, a growing percent of it digital, Disney has to organize its collection much more meticulously than ever before. Maintaining order and cleanliness among the (digital) heaps is a little team of R&D engineers and also details scientists within Disney's Direct-to-Consumer & International (DTCI) Technology team. DTCI was developed in 2018, in part to combine engineers and knowledge from throughout The Walt Disney Firm as well as align innovation to support the substantial selection of distinct material and business requirements at Disney.",
    "The structure of the business system is metadata: information regarding the stories, scenes and characters in Disney's programs and motion pictures. For example, Bambi would have metadata tags that identify not just characters like Thumper the bunny or Faline (Bambi's fawn friend), yet the sort of animal, partnerships between pets, and the character archetypes each pet depicts. Points like the nature scenes-- to the certain sorts of flowers illustrated-- music, view as well as tone of the tale, additionally have particular tags. Because of this, appropriately labeling every one of this web content with the right metadata that enables it to be sorted effectively is tough, specifically thinking about the sheer pace of Disney's growth:",
    "We have brand-new characters in TELEVISION programs, football gamers transforming teams, new weapons for superheroes, new shows, claimed Miquel Farré, the team's technical lead, and all of it requires a load of fresh metadata.",
    "With the help of Artificial intelligence solutions, he and also his team are developing machine-learning and deep-learning devices to immediately tag this material with detailed metadata to make the archiving procedure much more effective. As a result, writers and also animators can swiftly look for, and acquaint themselves with, everyone from Mickey Mouse to Modern Household's Phil Dunphy.",
    "What's so enchanting concerning metadata? \n The group leading this work was originally formed in 2012, as a part of the Disney & ABC Television Team. Throughout the years, it has actually expanded, and also now as a part of Disney's DTCI Innovation team, it has come to be the index and also knowledge base of the styles as well as conventions of the Disney world (as an example, in Bambithe animals talk-- in Snow White, they do not). For their machine-learning tools to generate metadata that precisely describes the creative web content, the group depends upon the authors as well as animators to explain the stylistic features that make each program special.",
    "These innovative team members benefit from their cooperation. When the material is tagged with accurate metadata, they can swiftly discover what they require with a search interface. For instance, a writer for Grey's Anatomy, to avoid redundancy, could need to understand the number of times the Whipple surgical procedure has been featured in an episode. On the other hand, a musician attracting underwater life for a new animation occurring under the sea might wish to try to find a certain personality present or positioning in The Little Mermaidor Searching for Nemo for ideas.",
    "However marking whatever with the best metadata rapidly presents a labor issue: even though hand-operated tagging is a vital part of the procedure, the DTCI Modern technology team does not have time to manually classify every framework. That's why Farré's team has actually placed artificial intelligence-- as well as a lot more recently, deep knowing-- to the job of producing metadata. The objective is to construct deep-learning algorithms that can instantly identify the elements of a scene in a way that follows the remainder of the Disney knowledge base. People still require to accept the formula's tags, yet the task is meaningfully lowering the work that goes into arranging the Disney collection, boosting the accuracy of searches within it.",
    "What's even more, this progression is liberating engineers to concentrate more on establishing deep-learning versions making use of Machine Learning. And also because of this, their efforts to automate metadata creation across various kinds of Disney web content are humming along.",
    "Deep understanding gives animations an identity among one of the most effective deep learning/metadata tasks has actually been resolving the troubles offered by computer animation recognition.",
    "In a live-action film or TELEVISION show, for a maker, separating a character from her surroundings is relatively simple. Yet animation makes things more complex. As an example, take an animated scene in which a personality shows up both real and in a poster (say the character is a criminal, and Desired indicators have actually been posted throughout town). 'For an algorithm, this is extremely complex', said Farré.",
    "In the last year, Farré's team has actually developed a deep-learning technique that can identify computer animated personalities from their fixed depictions, identify them within a crowd of doppelgangers (as in DuckTales, where lots of characters are basically similar), and also acknowledge them in scenes with fashionable lighting (in Alice in Heaven, when Alice initially satisfies in the Cheshire Cat, all he exposes is his toothy smile). Having actually determined what's what, the formula can mark the scenes with the suitable metadata.",
    "But the model's real power is that it can be applied to any piece of animated web content. That is, as opposed to create a new model for every Goofy, Hercules, and Elsa, the team only require utilize their generic model, which, with mild adjustments, will help any character in any kind of show or motion picture.",
    "Before this year, the team was working with even more traditional machine-learning formulas, which need less information than a deep-learning method-- however also caused a lot more limited, much less adaptable results. With fewer data inputs, conventional formulas carry out well. But when you have tremendously a lot more data is when deep knowing can make a huge distinction.",
    "Currently, says Farré, the deep-learning design can gain from already educated networks and also tweak for specific-use situations. In the particular case of computer animated personalities, Disney fine-tuned a semantic network with thousands of pictures to make certain it recognizes the idea of an computer animated personality. After that, for each and every particular show, the semantic network is readjusted utilizing just a couple of hundred photos from across a couple of episodes for it to discover exactly how animated characters ought to be found and translated within the details program.",
    "Machine Learning has been a key partner in Disney's change from traditional maker finding out to deep discovering, particularly when it comes to experimentation. That permits the group to quickly evaluate new versions of the design. (For the animation-recognition project, Disney is making use of the PyTorch structure with pre-trained designs.) Given that there's a great deal of study taking place in deep learning, the group is constantly trying out new and also unique approaches.",
    "The metadata research has actually been so effective that departments throughout Disney have caught wind. Farré claimed his team recently engaged with ESPN's customization team to give in-depth metadata concerning all the write-ups and also video clips that take place the industry-leading digital applications as well as web sites. If the product recognizes you are a fan of the Los Angeles Dodgers, Steph Curry, the Minnesota Vikings, and Manchester United, the more metadata it has regarding each short article can ensure you are offered web content most straightened to your choices. Additionally, the machine-learning algorithms, and the metadata they supply, can power advanced AI to drive additionally implicit customization (based upon information partnerships and behavior) over time.",
    "As Farré sees it, the applications for metadata are limitless, particularly given Disney's large and also growing collection of distinctive content, personalities, and products. 'I assume we will not get tired,' he claimed.",
  ],
  title: 'Image & Video Recognition',
  subtitle:
    'Disney: How Disney is revolutionizing search through AI',
  cover: {
    src: "/assets/common/case-studies-images/CaseStudy1/CaseStudy1.jpg",
    srcSet: '/assets/common/case-studies-images/CaseStudy1/CaseStudy1.jpg',
  },
};

export const caseStudy2 = {
  // headline:
  //   "Disney's substantial archive spans the program of nearly a century of material, which can transform any kind of look for details characters, scenes or on-screen items within it right into a significant task. Nonetheless, a team of scientists from Disney's Direct-to-Consumer & International Company (DTCI) have actually developed a machine discovering platform to aid automate the electronic archival of all that material. They call it the Content Genome.",
  quote: "",
  text: [
    "BuildFax began in 2008 by accumulating dispersed building allow information from throughout the USA as well as supplying it to various other organizations, such as insurance companies, developing examiners, and also economic experts. Today it provides solutions customized to those careers along with a variety of services, including indices that track patterns like housing remodels as well as new business construction. The business is based in Asheville, N.C",
    "The Obstacle BuildFax's core customer base is insurer, which spend billions of bucks yearly on roof covering losses. The firm gives quotes on the age as well as problem of roofs to help its customers develop plans as well as costs.",
    "BuildFax initially built anticipating models based on POSTAL CODE as well as other basic data using Python as well as R languages, however developing the models was complicated and also the outcomes did not provide enough differentiators to boost business.",
    "BuildFax required a solution that was much easier to use as well as would sustain much faster, more precise modeling for property-specific price quotes.",
    "Utilizes Artificial intelligence to supply roof-age and also job-cost estimates for insurance providers and also contractors, with property-specific worths that do not require to count on wide, ZIP code-level estimates. Makes use of information collections from public sources and from customers to construct versions.",
    "The Conveniences The very best methods as well as ease of use constructed into Machine Learning (ML) dramatically streamline the process of structure predictive designs.",
    "Versions that previously took 6 months or longer to produce are currently running in four weeks or much less. BuildFax can give clients with easy, programmatic accessibility to predictions with APIs.",
    "It produces chances for brand-new data analytics services that BuildFax can provide to consumers, such as message analysis in ML to estimate job expenses with 80 percent accuracy."
  ],
  title: 'Forecasting',
  subtitle:
    'Forecasting: How BuildFax leverages Machine Learning for Predictions',
  cover: {
    src: "/assets/common/case-studies-images/CaseStudy2/CaseStudy2.jpeg",
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy3 = {
  // headline:
  //   'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Pomelo Fashion, a worldwide fashion e-commerce solution based in Southeast Asia, had been displaying things on its web site in much the same means considering that it was founded in 2013. The setup had expanded stagnant, as well as that the formula for displaying the things count on old information streams with limited inputs and erratic precision. So as a fast-growing, ingenious startup, Pomelo Fashion set out to create individualized consumer experiences that would certainly boost the discoverability of new products and rise earnings-- and it required an option that would certainly do so at scale.",
    "Pomelo Style relied on Machine Learning, which allows programmers to build applications with the exact same machine learning (ML) modern technology used by huge ventures for real-time personalized referrals. By using Customization Algorithms with Machine Learning Pomelo Style created a distinct, personalized shopping experience that improves customer engagement and also extra efficiently converts it into sales.",
    "Pomelo Style sells clothing online and in 18 retail locations throughout Southeast Asia. Delivering to virtually 2 million consumers in greater than 50 nations, the business currently utilizes 500 staff members across its company workplaces, stores, as well as storage facilities. Its gross profits tripled from 2017 to 2018, increased from 2018 to 2019, and gets on track to increase in 2020 despite the total worldwide economic climate being down-- in July 2020 alone, the firm reported $7.5 million in profits. For many years, Pomelo Fashion rely upon an algorithm that placed products on classification web pages-- such as Outfits, Blouses, and Pants & Bottoms -- based upon page sights and also sales, blending the fads of the past 1 month with life time actions, item rate, as well as latest launches. The ranking was determined daily and also saved in a database, providing an identical experience for every single customer by nation.",
    "But as Pomelo Style grew, it recognized that enhancing the algorithm with ML would improve the high quality of recommendations on group web pages for consumers, leading to greater digital user engagement as well as conversion. Group pages produce the biggest section of sales for Pomelo Fashion: 38 percent of bought items are discovered by consumers on classification pages. Enhancing the significance of the products shown on these web pages had huge potential to uplift income. Even if consumers wouldn't buy the advised product, they would certainly enter a funnel to see other products on web pages like Color Example, Shop the Appearance, as well as Just for You, which create 30 percent of Pomelo Fashion's income.",
    "So, because Pomelo Fashion really did not have the facilities to produce customized experiences at scale to aid with product discoverability, it determined to incorporate ML Personalization. Without that, we would certainly not have gotten this off the ground, says Leese. We were trying to develop internal event tracking but were taking a look at a pretty messy set of occasion data. Our options engineer could see this would be a lengthy roadway, so he recommended getting it on board to save more designer time than it would cost. With the data flowing, we didn't have to develop a lot of framework to make this take place.",
    "The brand-new reasoning sorts items on category web pages based upon individual customers' choices. Clients' product communications-- their clicks, add-to-cart choices, shopping list, purchases, and also more-- are utilized to predict which items they are more than likely to discover interesting. Product information, such as rate, color, and category, are associated with customer information, such as their place, so that the ML version can much better find similar products and consumers. The even more item as well as client data the ML model processes, the a lot more accurately it makes recommendations. New Pomelo Fashion customers are first shown a preferred sorting of products, and also in just a few mins, the ML design customizes the sorting based upon their predicted choices.",
    "After a beta implementation proved stable, we began to recognize the complete possibility of the service as well as made it a central part of our customization roadmap, states Leese. Within a month, our roi increased by 400 percent for our 'Just for You' recommendations carousel through hyperparameter optimization as well as extra metadata. Afterwards, we started to use various other 'recipes' or versions to various other parts of our site. As a beginning point, Pomelo Style qualified and applied a personalized-ranking dish to its gowns group, bring about a 10 percent increase in click-through rates from a classification page to an individual item web page as well as an 18.3 percent boost in earnings. After fine-tuning the solution based upon the data from the outfits classification, Pomelo Style increased it to other classifications.",
    "Pomelo Fashion is presently using its customized ranking algorithm on all its classifications, besides new kid on the blocks as well as choose collections. As of November 2020, 60 percent of item views originate from personalization sustained recommendations. Pomelo Fashion has actually boosted gross revenue from group web pages by as much as 15 percent, click-through rates from category to product web pages by up to 18 percent, as well as add-to-cart clicks from the category page by as much as 16 percent. This development allowed the firm to open an 8 percent gain in step-by-step gross revenue.",
    "Released in 2013, Pomelo Fashion is a worldwide e-commerce service that offers garments as well as accessories on its internet site, on Android and iphone apps, as well as in physical kiosks. Headquartered in Thailand, it has almost two million consumers in greater than 50 nations.",
  ],
  title: 'Product Recommendation',
  subtitle:
    'Product Recommendation: Pomelo Increases Revenue for Fashion Shoppers with Personalization',
  cover: {
    src: '/assets/common/case-studies-images/CaseStudy3/CaseStudy3.png',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy4 = {
  // headline:
  //   'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Constructing a relied on ecommerce brand name calls for a focus on customer experience technology. Particularly in the apparel sector, traditional, hands-on retail experience has shaped customer assumptions. In words of Ameen Kazerouni, head of artificial intelligence study and also platforms at Zappos, The holy grail of ecommerce is to get consumers to really feel as comfy as they would in a store-- knowing exactly how an item fits, how it looks, with as much self-confidence as if they were physically trying it on.",
    "Achieving this type of personal experience in a digital shop is no small job. Yet if any type of business depends on it, it's Zappos. Developed in 1999, Zappos.com is a major online clothing retailer well-known for its industry-leading, 365-day return plan, fast and also free delivery, and 24/7 service.",
    "The company measurably boosted the ecommerce client experience using analytics as well as artificial intelligence. The solution enables Zappos to customize sizing and also search results page for private users while preserving an extremely fluid and receptive individual experience.",
    "Zappos understands that providing accurate recommendations is essential to an efficient buying experience. The business comforts clients with its charitable return policy as well as rapid as well as free shipping, yet these offerings are both costly and also uniform.",
    "We are always asking ourselves: exactly how do we set apart further? says Kazerouni. Exactly how do we enhance return prices without negatively influencing the customer experience? These are the troubles we laid out to solve utilizing artificial intelligence as well as analytics on AWS.",
    "In the search stage of the client trip, the firm's goal was to make individualized recommendations at runtime to raise search relevance. Rather than making use of a generic search algorithm, Zappos looks for to comprehend consumers personally and offer an one-of-a-kind set of search engine result for a given term. (It additionally plainly displays an opt-out switch for customers who do not desire this level of customization.).",
    "At the same time, it can't afford to decrease search performance noticeably. We needed to minimize the quantity of time the added operations take, keeps in mind Kazerouni. So we incorporate high-performance caching, critical precalculation of specific results, and also ensemble-based machine learning approaches that utilize multiple, simple models.",
    "Zappos uses numerous modern technologies for training as well as running designs. It depends on ML to anticipate client garments dimensions. These forecasts are cached and after that subjected at runtime through microservices APIs for usage in suggestions. Zappos utilizes cloud technology to run huge information analytics for a portion of the cost of traditional on-premises clusters. It additionally runs designs using graphical processing units (GPUs). This totally taken care of key-value as well as record data source delivers single-digit millisecond efficiency at nearly any scale. It can deal with greater than 10 trillion requests a day and can sustain heights of more than 20 million requests per secondly. This solution makes sure sub-millisecond latency when needed.",
    "Run, Do Not Walk. Creating and also maintaining this complex design with standard advancement as well as deployment methods would be prohibitively intricate. Rather, Zappos depends on facilities as code using cloud technology. Zappos delivered these improved search results to clients with a nearly undetected increase in latency, with 99 percent of searches completed in less than 48 nanoseconds. By using a similar style, it has actually likewise dramatically improved individualized sizing referrals based upon straightforward fit studies and past purchases. Because of this, the business has decreased duplicated searches as well as product returns. It has actually also achieved higher search-to-product-clickthrough rates and also elevated the position of consumer choices in search results page.",
    "As Kazerouni sums it up, We think about ourselves as a client service firm that occurs to sell shoes as well as apparel. Anything we can do that enhances service enhances our organization. Making use of ML makes it possible for us to introduce the experience much faster.",
    "Zappos began 20 years back as a small, on the internet footwear seller. Ever since, it has actually grown to market apparel, handbags, devices, and extra while giving distinguished customer care as well as cutting-edge staff member experiences.",
  ],
  title: 'Customer Analytics',
  subtitle:
    'Customer Analytics: Creating World-Class Customer Experiences with ML',
  cover: {
    src: '/assets/common/case-studies-images/CaseStudy4/CaseStudy4.jpeg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy5 = {
  headline:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Intelligent machines that work beyond standard automation as well as take advantage of core AI abilities to learn from its human managers about just how to make use of info accumulated.",
    "Let's break down the AI capacities that IPA is producing: Smart workflows - Tracks the standing of the end-to-end process in real time, manages handoffs between systems and also people to give statistical data on traffic jams, and improves procedure visibility to boost procedure efficiency.",
    "Machine learning - Make predictions based on data inputs and also analytics as well as supplies insights on acknowledged patterns.",
    "Natural Language Processing (NLP) - Analyze text-heavy interactions and recognizes interaction intent.",
    "Natural Language Generation (NLG) - Produces stories from the gathered information to offer a far better tale for data-driven decision making.",
    "Cognitive representatives - Technologies that integrate machine learning, NLG, and also NLP to construct an online workforce capable of performing extra advanced jobs with intelligence, such as supplying consumer involvement recommendations to human supervisors.",
    "Much of the cases procedures in traditional insurance provider require hrs of hand-operated effort by staff members. IPA systems can automate a lot of those vital, but routine, actions. IPA can port over client information from completed claims types into the firm's CRM or various other data source with field mapping. For printed kinds, it can check, digitize, and port that information right into the database too. This removes hours of click work and also data entrance for claims employees.",
    "McKinsey forecasts that automation will certainly decrease the price of the insurance claims trip by up to 30%, while Autonomous reports the insurance coverage sector could see a $1.5 trillion decrease from automating insurance claims processing.",
    "Claims automation increases effectiveness and reduces functional prices by lowering staffing degrees, ignoring seasonal re-training and also multi-skilling and makes certain that skilled cases operators can be committed to greater value tasks and intricate processes."
  ],
  title: 'Process Automation',
  subtitle:
    'Process Automation: Intelligent Process Automation applied to Insurance Claims',
  cover: {
    src: '/assets/common/case-studies-images/CaseStudy5/CaseStudy5.jpeg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy6 = {
  headline:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Drug Discovery is the very first step of the value chain that determines brand-new candidate rehabs for treating or healing human illness. It is the first phase of biopharma R&D and also includes the recognition and optimization of potential brand-new medications and also a preclinical in vivo recognition via cell assays and animal designs. Effective prospects that meet the regulatory needs used to drug discovery move into the scientific trial phase, where they are checked for efficacy and safety in people.",
    "Recursion Pharmaceuticals is producing an AI-enabled human biology map to speed up discovery of therapies for unusual, without treatment conditions.",
    "Recursion Pharmaceuticals is producing an AI-enabled map of human biology to speed up discovering appealing therapies for dozens of illness with unmet clinical need. The firm, headquartered in Salt Lake City, Utah, has developed a medication discovery system that integrates chemistry, automated biology, as well as cloud computer devices to reveal new restorative prospects, possibly cutting the time to uncover and also create a brand-new medicine by a factor of 10.",
    "It's a mystery, which onlookers call Eroom's Law: drug discovery, regardless of improvements in medical modern technology, has actually ended up being significantly slower as well as a lot more expensive. Therefore, the variety of FDA-approved new medications getting to patients has actually stagnated, while the prices of pharmaceutical R&D has actually remained to increase.",
    "Recursion is seeking to resolve this paradox by combining a deep understanding of biology with the current in artificial intelligence devices to reimagine the medicine exploration and advancement process. The business aims to use this method to much more quickly and reasonably discover brand-new medicines for lots of illness, both rare and usual.",
    "In this pursuit, design, AI-pattern acknowledgment, and also cloud computer have actually verified as necessary as biochemical expertise in crafting the service. Recursion's data pipeline incorporates image processing, inference engines, and also deep learning modules. Its designers have built a system that sustains ruptureds of computational power that weigh in at trillions of calculations per second.",
    "Recursion is already obtaining outcomes. In simply under two years, Recursion has actually developed thousands of uncommon condition designs and created a shortlist of medication prospects across a number of conditions, consisting of moving a compound right into Stage 1 clinical development towards therapy for cerebral spacious malformation, an unusual hereditary stroke disorder. The prospect is among the very first to make it to human screening that was found utilizing artificial intelligence. It typically takes at the very least 10 years to develop a brand-new medicine.",
    "Our objective is to uncover 100 clinical prospects in the initial one decade of the business, which would certainly be orders of magnitude more than even the largest pharmaceutical firms can accomplish right now, claims Recursion's Elderly Vice Head of state, Translational Discovery/Chief Evangelist, Ron Alfa."
  ],
  title: 'Drug Discovery',
  subtitle:
    'Drug Discovery: New Drug Discovery through using Artificial Intelligence',
  cover: {
    src: '/assets/common/case-studies-images/CaseStudy6/CaseStudy6.jpeg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy7 = {
  headline:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Well"
  ],
  title: 'Adidas will release your favourite shoes',
  subtitle:
    'Get your favourite Adidas shoes, clothing & accessories at the official website! Adidas Training.',
  author: {
    photo: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    name: 'Jack Smith',
    date: 'May 20, 2019',
  },
  cover: {
    src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};

export const caseStudy8 = {
  headline:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly. It is advisable to plan the event around a theme in order to create a focal point for the barbecue party. This way, all aspects of the party such as the food, games and decorations can be fashioned around a central theme. Favorite themes for barbecue parties include a Hawaiian motif, nostalgic seventies get-together, or, if you are near the sea, opt for a beach bbq party!',
  quote: "",
  text: [
    "Well"
  ],
  title: 'Adidas will release your favourite shoes',
  subtitle:
    'Get your favourite Adidas shoes, clothing & accessories at the official website! Adidas Training.',
  author: {
    photo: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    name: 'Jack Smith',
    date: 'May 20, 2019',
  },
  cover: {
    src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
    srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
  },
};
