export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Discover things automatically`,
    subtitle:
      'Spot as well as categorize numerous things including the area of each item within the picture. Find out more about item discovery',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Gain knowledge at the edge',
    subtitle:
      'Construct as well as deploy quickly, high-accuracy versions to identify pictures or discover objects at the side, and also activate real-time actions based on data. Our technology sustains a selection of side devices where sources are constrained as well as latency is vital.',
  },
  {
    icon: 'far fa-heart',
    title: 'Reduce acquisition rubbing',
    subtitle:
      'With us, stores can produce an appealing mobile experience that allows your consumers to submit a photo of an item and also quickly see a checklist of similar products for purchase from you.',
  },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Understand text and also act on it',
    description:
      "We utilize Optical Character Recognition to find text within pictures in more than 50 languages and also different data types. It's also part of Paper AI, which lets you process millions of records promptly and also automate company process.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Find explicit material',
    description:
      'Our algorithms can evaluate your pictures and estimate the chance that any kind of offered picture includes negative content, violence, and also extra.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Utilize our data labeling solution',
    description:
    "If you have photos that aren't yet identified, we have a group of people that can assist you annotate photos, video clips, and message to get high-quality training information.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];