import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const SpeechToText = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Speech To Text"
      mainHeaderTitle="Speech To Text"
      mainHeaderTitleVariant={"h3"}
      mainHeaderSubTitle="Precisely transform speech right into message using our AI powered technology"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/SpeechToText/SpeechToText_AI.svg"}
    />
  )
}

export default SpeechToText;
