import { colors } from '@material-ui/core';

export const companyInfo = [
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    name: 'INVESTMENT',
    title:
      "To date, Scale has raised $277 million in funding, with its latest funding round being a Series D in 2020.",
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/mailchimp.svg',
    name: 'OUR TEAM',
    title:
      "We come from all different backgrounds. Scale's team is composed of educators, parents, poker players, boba lovers, and more.",
  },
  {
    logo: 'https://assets.maccarianagency.com/the-front/logos/dropbox.svg',
    name: 'OUR TIME',
    title:
      'Coming out of YC S16, we have been able to partner with industry leaders to accelerate their AI development.',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const jobs = [
  {
    color: colors.blue[500],
    title: 'web design',
    jobTitle: 'Web designer internship',
    type: 'Full time',
    location: 'Milan, Italy',
    date: '3 days ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.purple[500],
    title: 'business analytics',
    jobTitle: 'Senior business analysts',
    type: 'Full time',
    location: 'Rome, Italy',
    date: 'Just now',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
  {
    color: colors.amber[500],
    title: 'photography',
    jobTitle: 'Mid-level photographer',
    type: 'Remote',
    location: 'Yerevan, Armenia',
    date: '1 week ago',
    companyLogo: 'https://assets.maccarianagency.com/the-front/logos/atlassian.svg',
    companyName: 'Atlassian',
  }
];