import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Typography,
  TextField,
  Button,
  colors,
  Hidden,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import { Image } from '../../common/atoms';
import { SectionHeader, IconAlternate, TypedText } from '../../common/molecules';
import { CardBase, CardProduct } from '../../common/organisms';
import CustomButton from '../../components/CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  typed: {
    fontWeight: 'bold',
  },
  listItemAvatar: {
    minWidth: 28,
  },
  formCover: {
    objectFit: 'cover',
    borderBottomLeftRadius: '40%',
  },
  cardProduct: {
    minWidth: 400,
    [theme.breakpoints.up('md')]: {
      maxWidth: 400,
    },
  },
  marginTop: {
    marginTop: 100,
  },
  inputBackground: {
    background: 'grey'
  }
}));

const ContactForm = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} classes={classes.marginTop}>
        <Grid
          item
          container
          justify="flex-start"
          alignItems="center"
          xs={12}
          md={6}
          data-aos="fade-up"
        >
          <CardBase withShadow liftUp>
            {/* <Grid container spacing={isMd ? 4 : 2}>
              <Grid xs={12}>
                <Typography variant="h6" color="textPrimary">
                  FILL OUT THE FORM BELOW
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="First Name"
                  variant="outlined"
                  size="medium"
                  name="firstName"
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Last Name"
                  variant="outlined"
                  size="medium"
                  name="lastName"
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Company"
                  variant="outlined"
                  size="medium"
                  name="company"
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Phone"
                  variant="outlined"
                  size="medium"
                  name="phone"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Work Email"
                  variant="outlined"
                  size="medium"
                  name="email"
                  fullWidth
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Message"
                  variant="outlined"
                  name="message"
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item container justify="flex-start" xs={12}>
                <CustomButton
                  variant="contained"
                  type="submit"
                  size="large"
                  label="Send"
                />
              </Grid>
            </Grid> */}
            <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/2Vpw2StsikfhObXGEwBBP6QPTnxlmUThHR11jvoL4H5rKvJeemgrSRe50nyUtHsTV">
              
            </div>
          </CardBase>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            container
            alignItems="center"
            justify="flex-end"
            xs={12}
            md={6}
            data-aos="fade-up"
          >
            <Grid item xs={10}>
              <SectionHeader
                label="make things done"
                title={
                  <>
                    <span>
                      Talk To Us
                      <br />
                      <Typography color="primary" variant="inherit" component="span">Talk and discuss</Typography>
                      <br />
                    </span>
                    <TypedText
                      component="span"
                      variant="h4"
                      color="primary"
                      className={classes.typed}
                      typedProps={{
                        strings: ['Schedule', 'meetings', 'and ask questions.'],
                        typeSpeed: 50,
                        loop: true,
                      }}
                    />
                  </>
                }
                align="left"
                disableGutter
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default ContactForm;
