import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from '../../common/molecules';
import { Section, CardBase } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const Privacy = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <>
        <DescriptionCta
          title="Terms of Use & Privacy Policy"
          align={'left'}
          titleProps={{
            className: classes.fontWeightBold,
            color: 'textPrimary',
          }}
          subtitleProps={{
            color: 'textSecondary',
          }}
        />
        <Divider className={classes.divider} />
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <SectionHeader
              title="Data protection"
              subtitle="1. Data protection at a glance"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
            />
            <SectionHeader
              title="General information"
              subtitle="The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data are all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration listed below this text."
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              disableGutter
            />
            <SectionHeader
              title="1. Data collection on this website"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              disableGutter
            />
            <List className={classes.list}>
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Who is responsible for data collection on this website?
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                The data processing on this website is carried out by the website operator. You can find their contact details in the imprint of this website.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  How do we collect your data?
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  On the one hand, your data is collected when you communicate it to us. This can be, for. E.g. data that you enter in a contact form.
                  
                  Other data are recorded automatically or with your consent when you visit the website by our IT systems. This is mainly technical data (e.g. internet browser, operating system or time of the page view). This data is collected automatically as soon as you enter this website.
                </Typography>
              </ListItem>
              
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  What do we use your data for?
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  Some of the data is collected in order to ensure that the website is error-free. Other data can be used to analyze your user behavior.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  What rights do you have with regard to your data?
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request that the processing of your personal data be restricted under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority.
                  
                  You can contact us at any time at the address given in the legal notice if you have any further questions on the subject of data protection.
                </Typography>
              </ListItem>
            </List>
            <SectionHeader
              title="2. Hosting and Content Delivery Networks (CDN)"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              disableGutter
            />
            <List className={classes.list}>
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  External hosting
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                This website is hosted by an external service provider (hoster). The personal data recorded on this website is stored on the host's servers. This can primarily involve IP addresses, contact requests, meta and communication data, contract data, contact details, names, website accesses and other data generated via a website.

                The hoster is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 Para. 1 lit.b GDPR) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6 Para . 1 lit.f GDPR).

                Our host will only process your data insofar as this is necessary to fulfill its performance obligations and follow our instructions with regard to this data.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Conclusion of an order processing contract
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  On the one hand, your data is collected when you communicate it to us. This can be, for. E.g. data that you enter in a contact form.
                  
                  Other data are recorded automatically or with your consent when you visit the website by our IT systems. This is mainly technical data (e.g. internet browser, operating system or time of the page view). This data is collected automatically as soon as you enter this website.
                </Typography>
              </ListItem>
            </List>
            <SectionHeader
              title="3. General information and mandatory information"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              dis
              ableGutter
            />
            <List className={classes.list}>
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Data protection
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  
                  The operators of this website take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.

                  When you use this website, various personal data are collected. Personal data are data with which you can be personally identified. This data protection declaration explains which data we collect and what we use it for. It also explains how and for what purpose this is done.

                  We would like to point out that data transmission over the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.

                  <br /><br />
                  Note on the responsible body
                  The responsible body for data processing on this website is:

                  <br /><br />
                  Email: info@capsula.ai
                  <br /><br />
                  The responsible body is the natural or legal person who, alone or jointly with others, decides on the purposes and means of processing personal data (e.g. names, email addresses, etc.).
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                Revocation of your consent to data processing
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  Many data processing operations are only possible with your express consent. You can revoke your consent at any time. An informal e-mail to us is sufficient. The legality of the data processing carried out before the revocation remains unaffected by the revocation.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                Right to object to the collection of data in special cases and to direct advertising (Art. 21 GDPR)
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                IF THE DATA PROCESSING BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA FOR REASONS ARISING FROM YOUR SPECIAL SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RELEVANT LEGAL BASIS ON WHICH PROCESSING IS REQUIRED, CAN BE REFERRED TO IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE CAN PROVIDE COMPULSORY REASONS FOR PROCESSING THAT OCCURS, EXECUTES OR EXECUTES YOUR INTEREST, OBSERVES THE RIGHTS OBJECTION ACCORDING TO ART. 21 PARA. 1 GDPR).
                <br /> <br />
                IF YOUR PERSONAL DATA ARE PROCESSED IN ORDER TO OPERATE DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA FOR THE PURPOSE OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING TO THE EXTENT IN CONNECTION WITH SUCH DIRECT ADVERTISING. 
                <br /> <br />
                IF YOU OBJECT, YOUR PERSONAL DATA WILL NO LONGER BE USED FOR THE PURPOSE OF DIRECT ADVERTISING (OBJECTION ACCORDING TO ART. 21 (2) GDPR).
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Right of appeal to the competent supervisory authority
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  In the event of violations of the GDPR, the data subjects have the right to lodge a complaint with a supervisory authority, in particular in the member state of their habitual residence, their place of work or the place of the alleged violation. The right to lodge a complaint exists without prejudice to other administrative or judicial remedies.
                </Typography>
              </ListItem>
              
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Right to data portability
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another person responsible, this will only be done if it is technically feasible.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                SSL or TLS encryption
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                For security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the website operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http: //" to "https: //" and by the lock symbol in your browser line.

                If the SSL or TLS encryption is activated, the data that you transmit to us cannot be read by third parties.                  
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Information, deletion and correction
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  Within the framework of the applicable statutory provisions, you have the right to free information about your stored personal data, their origin and recipient and the purpose of the data processing and, if necessary, a right to correct or delete this data. You can contact us at any time at the address given in the legal notice if you have any further questions on the subject of personal data.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Right to restriction of processing
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  You have the right to request that the processing of your personal data be restricted. You can contact us at any time at the address given in the legal notice. The right to restriction of processing exists in the following cases:
                  <ul>
                    <li>
                      If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the test, you have the right to request that the processing of your personal data be restricted.
                    </li>
                    <li>
                      If the processing of your personal data happened / happens unlawfully, you can request the restriction of the data processing instead of the deletion.
                    </li>
                    <li>
                      If we no longer need your personal data, but you need them to exercise, defend or assert legal claims, you have the right to request that the processing of your personal data be restricted instead of being deleted.
                    </li>
                    <li>
                      If you have lodged an objection in accordance with Art. 21 Paragraph 1 GDPR, your interests and ours must be weighed up. As long as it is not yet clear whose interests prevail, you have the right to request that the processing of your personal data be restricted.
                    </li>
                  </ul>
                  <br />
                  If you have restricted the processing of your personal data, this data - apart from its storage - may only be allowed with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest processed by the European Union or a member state.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Objection to advertising emails
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  We hereby object to the use of the contact data published in the context of the imprint obligation for sending unsolicited advertising and information materials. The operators of the pages expressly reserve the right to take legal action in the event of unsolicited sending of advertising information, such as spam e-mails.
                </Typography>
              </ListItem>
            </List>
          
            <SectionHeader
              title="4. Data collection on this website"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              dis
              ableGutter
            />
            <List className={classes.list}>
              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Server log files
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:

                  <li>Browser type and browser version</li>
                  <li>Operating system used</li>
                  <li>Referrer URL</li>
                  <li>Host name of the accessing computer</li>
                  <li>Time of the server request</li>
                  <li>IP address</li>
                  <br /><br />
                  This data will not be merged with other data sources.
                  <br /><br />
                  This data is recorded on the basis of Art. 6 Paragraph 1 lit. f GDPR. The website operator has a legitimate interest in the technically error-free presentation and optimization of his website - the server log files must be recorded for this.
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="h4" color="textPrimary">
                  Inquiries by email, phone or fax
                </Typography>
              </ListItem>

              <ListItem disableGutters>
                <Typography variant="body1" color="textPrimary">
                  If you contact us by e-mail, telephone or fax, your request, including all personal data derived from it (name, request), will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.
                  <br /><br />

                  This data is processed on the basis of Article 6 (1) (b) GDPR, provided that your request is related to the fulfillment of a contract or is necessary to carry out pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the inquiries sent to us (Art. 6 Para. 1 lit. f GDPR) or on your consent (Art. 6 Para. 1 lit. a GDPR) if this was queried.
                  <br /><br />

                  The data you send to us via contact requests will remain with us until you request us to delete it, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.
                </Typography>
              </ListItem>
            </List>
            <SectionHeader
              title="5. Plugins and Tools"
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              dis
              ableGutter
            />
            <List>
              <ListItem disableGutters>
                  <Typography variant="h4" color="textPrimary">
                    Google Web Fonts
                  </Typography>
                </ListItem>

                <ListItem disableGutters>
                  <Typography variant="body1" color="textPrimary">
                    This page uses so-called web fonts, which are provided by Google, for the uniform display of fonts. When you call up a page, your browser loads the required web fonts into your browser cache to display texts and fonts correctly.
                    <br /><br />
For this purpose, the browser you are using must connect to the Google servers. This gives Google knowledge that this website has been accessed via your IP address. The use of Google WebFonts is based on Art. 6 Para. 1 lit. f GDPR. The website operator has a legitimate interest in the uniform representation of the typeface on his website. If a corresponding consent has been requested (e.g. consent to the storage of cookies), processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a GDPR; the consent can be withdrawn at any time.
<br /><br />
If your browser does not support web fonts, a standard font will be used by your computer.
                  </Typography>
                </ListItem>
            </List>
          </Grid>
        </Grid>
        </>
      </Section>
      <Divider />
    </div>
  );
};

export default Privacy;
