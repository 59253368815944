export const light = {
  "alternate": {
    "main": "rgb(247, 249, 250)",
    "dark": "#e8eaf6",
    "template1": "#03dac6",
    "template2": "#3700b3"
  },
  "cardShadow": "rgba(23, 70, 161, .11)",
  "type": "light",
  "primary": {
    "main": "#3f51b5",
    "light": "rgb(71, 145, 219)",
    "dark": "rgb(17, 82, 147)",
    "contrastText": "#fff"
  },
  "secondary": {
    "light": "#ffb74d",
    "main": "#f9b934",
    "dark": "#f57c00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "text": {
    "primary": "#2d3748",
    "secondary": "#718096",
  },
  "divider": "rgba(0, 0, 0, 0.12)",
  "background": {
    "paper": "#fff",
    "default": "#fff",
    "level2": "#f5f5f5",
    "level1": "#fff",
    "footer": "#1b1642",
    "lightGray": "rgba(247,250,252,1)",
    "gradient1": "linear-gradient(173deg, rgba(3,218,198,1) 19%, rgba(55,0,179,1) 98%)",
    "gradient2": "linear-gradient(319deg, rgba(3,218,198,1) 19%, rgba(55,0,179,1) 98%)",
    "gradient3": "linear-gradient(59deg, rgba(3,218,198,1) 19%, rgba(55,0,179,1) 98%);",
    "gradient4": "linear-gradient(180deg, rgba(3,218,198,1) 6%, rgba(55,0,179,1) 86%)",
    "gradient5": "radial-gradient(circle, rgba(255,191,204,1) 0%, rgba(84,0,255,1) 100%)",
    "gradient6": "linear-gradient(53deg, rgba(255,191,204,1) 0%, rgba(84,0,255,1) 100%)",
    "gradient7": "linear-gradient(236deg, rgba(255,255,255,1) 0%, rgba(3,218,198,1) 46%, rgba(40,63,184,1) 100%)",
    "gradient8": "linear-gradient(180deg, rgba(255,191,204,1) 0%, rgba(255,255,255,1) 60%)",
    "gradient9": "linear-gradient(180deg, rgba(55,0,179,1) 0%, rgba(3,218,198,1) 42%, rgba(255,255,255,1) 73%)",
  },
};

export const dark = {
  "alternate": {
    "main": "#2D3748",
    "dark": "#24242b",
  },
  "cardShadow": "rgba(0, 0, 0, .11)",
  "common": {
    "black": "#000",
    "white": "#fff"
  },
  "type": "dark",
  "primary": {
    "main": "#90caf9",
    "light": "rgb(166, 212, 250)",
    "dark": "rgb(100, 141, 174)",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "secondary": {
    "light": "#ffb74d",
    "main": "#f9b934",
    "dark": "#f57c00",
    "contrastText": "rgba(0, 0, 0, 0.87)"
  },
  "text": {
    "primary": "#EEEEEF",
    "secondary": "#AEB0B4",
  },
  "divider": "rgba(255, 255, 255, 0.12)",
  "background": {
    "paper": "#1A202C",
    "default": "#121212",
    "level2": "#333",
    "level1": "#2D3748",
    "footer": "#18181f",
  },
};