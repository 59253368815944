import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from '../../common/molecules';
import { Section, HeroBackground } from '../../common/organisms';

const useStyles = makeStyles(() => ({
  textWhite: {
    color: 'white',
  },
  video: {
    height: 524,
    maxWidth: 900,
    margin: '0 auto',
  },
}));

const Video = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <HeroBackground
        backgroundImg="https://assets.maccarianagency.com/the-front/photos/logistics/cover@2x.png"
        backgroundPosition="center center"
        backgroundColor={'#11092d'}
      >
        <Section narrow disablePadding>
          <>
          <SectionHeader
            title={<span className={classes.textWhite}>Wtach Our Video</span>}
            data-aos="fade-up"
          />
          <div className={classes.video}>
            <iframe 
            width="760" 
            height="515" 
            src="https://www.youtube.com/embed/wuGBwQ7sQac" 
            frameborder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen="" 
            className={classes.video}
          >

            </iframe>
          </div>
          </>
        </Section>
      </HeroBackground>
    </div>
  );
};

Video.propTypes = {
  className: PropTypes.string,
};

export default Video;
