export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Ensuring clear communication`,
    subtitle:
      'Record your web content in real time or from saved data',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Making the most of technology',
    subtitle:
      'Deliver a better individual experience in products via voice commands',
  },
  {
    icon: 'far fa-heart',
    title: 'Providing excellent customer service',
    subtitle:
      'Gain insights from client communications to enhance your solution',
  },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Speech adjustment',
    description:
      'Customize speech recognition to record domain-specific terms as well as uncommon words by supplying tips as well as enhance your transcription accuracy of particular words or phrases. Immediately transform spoken numbers right into addresses, years, currencies, and much more making use of courses.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Domain-specific models',
    description:
      'Choose from an option of the trained models for voice control and also call and video clip transcription optimized for domain-specific high quality needs. As an example, our improved phone call version is tuned for audio originated from telephony, such as call taped at an 8khz sampling price.',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'CLIENT PORTAL ACCESS',
    title: 'Streaming speech recognition',
    description:
      "Get real-time speech acknowledgment results as the API processes the audio input streamed from your application's microphone or sent out from a prerecorded sound file (inline or via Cloud Storage Space).",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Speech-to-Text On-Prem',
    description:
      "Have full control over your infrastructure as well as safeguarded speech information while leveraging our speech acknowledgment modern technology on-premises right in your very own private information facilities. Call us to start.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/social-website.svg',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];