import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from '../../../common/molecules';
import { Section, SectionAlternate } from '../../../common/organisms';
import BlogItem from '../../../components/BlogItem/BlogItem';
import { articles3 } from './data/data';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.alternate.main
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContainer: {
    paddingBottom: theme.spacing(20),
  },
  textBlack: {
    color: 'black',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: theme.palette.primary.main,
  },
  pricingContainer: {
    position: 'relative',
    marginTop: theme.spacing(-20),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    },
  },
}));

const Blog = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root} {...rest}>
      <div className={classes.sectionContainer}>
        <SectionAlternate narrow className={classes.pagePaddingTop}>
          <>
          <SectionHeader
            title="Blog"
            subtitle="Company Updates & Technology Articles"
            titleProps={{
              className: classes.textBlack,
              variant: 'h3',
            }}
            subtitleProps={{
              className: classes.textBlack,
              variant: 'h5'
            }}
            data-aos="fade-up"
          />
          </> 
        </SectionAlternate>
      </div>
      <div className={classes.pricingContainer}>
        <div>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid justify="center" container spacing={isMd ? 4 : 2}>
              <Grid item xs={10} md={10} data-aos="fade-up">
                <BlogItem data={articles3} />
              </Grid>
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};

Blog.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default Blog;
