import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const ImageAndVideoRecognition = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Image & Video Recognition"
      mainHeaderTitle="Image & Video Recognition"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="How Disney is revolutionizing search through AI"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy1/CaseStudy1.jpg"}
      solutionsSectionSubtitle="We provide two computer vision products that use machine discovering to assist you understand your images with industry-leading forecast precision."
      learnMoreLink="/case-studies/1"
    />
  )
}

export default ImageAndVideoRecognition;