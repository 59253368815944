import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { SectionHeader } from '../../common/molecules';
import CustomButton from '../CustomButton/CustomButton';

export const ContentHeader = ({ label, title, subTitle, titleVariant, subtitleVariant, align, hideButton }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <SectionHeader
      align={align}
      label={label}
      title={title ||
        (<span>
          Launch Your Website{' '}
          <Typography component="span" variant="inherit" color="primary">
            Marketing Platform
          </Typography>
        </span>)
      }
      titleVariant={titleVariant}
      subtitleVariant={subtitleVariant}
      subtitle={subTitle || "We help digital agencies, local business and managed service providers to have the best Website Marketing service."}
      subtitleColor="black"
      ctaGroup={[
        !hideButton &&
        <CustomButton
          size={isMd ? 'large' : 'medium'}
          label="Talk To Us"
        />
      ]}
    />
  )
}
