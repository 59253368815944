import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import { SectionHeader } from '../../../common/molecules';
import { Section, SectionAlternate } from '../../../common/organisms';
import Welcome from '../../../components/Welcome/Welcome';
import { promoNumbers } from '../../LandingPage/data/data';
import clsx from 'clsx';
import Datasets from '../../../components/Datasets/Datasets';
import DatasetTable from '../../../components/DatasetTable/DatasetTable';
import GetStartedSection from '../../../components/GetStartedSection/GetStartedSection';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000000'
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  madcapSection: {
    backgroundColor: '#0000008a',
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  },
  getStartedSection: {
    color: theme.palette.background.gradient1
  }
}));

const OpenDatasets = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.fullHeight}>
        <Section className={classes.disablePaddingTop}>
          <Welcome 
            title="Open Datasets" 
            subtitle="Index of Open Datasets for Computer Vision and Natural Language Processing"
            btnTextColor={"Black"}
            btnBgColor="White"
          />
        </Section>
      </div>
      <Section>
        <Datasets data={promoNumbers} />
      </Section>
      <SectionAlternate>
        <SectionHeader
          title="List of Public Datasets"
          subtitle="There are public datasets about nearly any subject."
          titleProps={{
            className: clsx(classes.textBlack, classes.fontWeightBold),
            variant: 'h3',
          }}
          subtitleProps={{
            className: classes.textBlack,
          }}
          data-aos="fade-up"
        />
      </SectionAlternate>
        <DatasetTable />
      <Section narrow>
       <GetStartedSection />
      </Section>
    </div>
  );
};

export default OpenDatasets;
