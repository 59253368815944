import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const Forcasting = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Forcasting"
      mainHeaderTitle="Forcasting"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="How BuildFax leverages Machine Learning for Predictions"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy2/CaseStudy2.jpeg"}
      learnMoreLink="/case-studies/2"
    />
  )
}

export default Forcasting;