import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const VisionAI = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Vision AI"
      mainHeaderTitle="Vision AI"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Acquire insights from your images&nbsp; or utilize our API designs to identify emotion, items, recognize text, as well as much more."
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/VisionAI/Vision_AI.svg"}
      solutionsSectionSubtitle="We provide two computer vision products that use machine discovering to assist you understand your images with industry-leading forecast precision."
    />
  )
}

export default VisionAI;
