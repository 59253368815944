import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorButton = withStyles((theme) => ({
  root: {
    color: props => props.textColor || '#FFFFFF',
    backgroundColor: props => props.bgColor || '#000000',
    borderRadius: '2.4rem',
    padding: '9px 22.5px',
    '&:hover': {
      backgroundColor: props => props.bgColor || '#000000',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomButton({ label, href, ...rest }) {
  const classes = useStyles();

  return (
    <ColorButton
      component="a"
      href={href || "https://capsulaai.pipedrive.com/scheduler/PBPyp5hG/meeting"}
      target={!href ? "_blank" : ""}
      variant="contained"
      className={classes.margin}
      {...rest}
    >
      {label}
    </ColorButton>
  );
}
