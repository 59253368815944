import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { SectionHeader } from '../../common/molecules';
import CustomButton from '../CustomButton/CustomButton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
  }
}));

const Welcome = props => {
  const classes = useStyles();

  const { className, title, subtitle, btnTextColor, btnBgColor, ...rest } = props;

  return (
    <div className={clsx('jarallax', className)} {...rest}>
      <SectionHeader
        title={title || "We craft beautiful websites and digital products."}
        titleVariant="h2"
        titleClasses={classes.textWhite}
        subtitle={subtitle || "We design, develop and launch websites and products for startups, companies and ourselves."}
        subtitleVariant="h5"
        subTitleClasses={classes.textWhite}
        ctaGroup={[
          <CustomButton textColor={btnTextColor} bgColor={btnBgColor} variant="contained" size="large" label="Talk To Us" />
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

Welcome.propTypes = {
  className: PropTypes.string,
};

export default Welcome;
