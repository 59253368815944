import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SectionHeader } from '../../common/molecules';
import CustomButton from '../CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  textGradient: {
    background: theme.palette.background.gradient1,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: "Arial, sans-serif",
    fontWeight: 'normal',
    fontSize: '33px'
  }
}));

export default function GetStartedSection() {
  const classes = useStyles();
  return (
    <SectionHeader
      gradientText={classes.textGradient}
      title="Take The Next Step"
      titleVariant="h3"
      ctaGroup={[(
        <CustomButton variant="contained" size="large" label="Talk To Us" />
      )]}
    />
  )
}
