import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';
import { SectionHeader, DescriptionCta } from '../../common/molecules';
import { Section } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  textWhite: {
    color: 'white',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  checkBox: {
    background: 'transparent',
    borderRadius: 0,
    width: 30,
    height: 30,
  },
  list: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const Imprint = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <>
          <DescriptionCta
            title="Imprint"
            align={'left'}
            titleProps={{
              className: classes.fontWeightBold,
              color: 'textPrimary',
            }}
            subtitleProps={{
              color: 'textSecondary',
            }}
          />
          <Divider className={classes.divider} />
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={8}>
              <List className={classes.list}>
                <ListItem disableGutters>
                  <Typography variant="body1" color="textPrimary">
                    InnovationsCentrum Osnabrück (ICO)<br />
                    Albert-Einstein-Strasse 1<br />
                    49076 Osnabrück<br />
                    Germany
                    <br /><br />
                    Owner: Fatih Damat
                    <br /><br />
                    Tel .: +49 (0) 17621965113
                    <br /><br />
                    Email: info [at] capsula [dot] ai
                    <br /><br />
                    Sales tax identification number according to § 27 a sales tax law: DE334026903
                    <br /><br />
                    Platform of the EU Commission for online dispute resolution:  https://ec.europa.eu/odr
                    <br /><br />
                    We are not obliged to participate in a dispute settlement procedure before a consumer arbitration board, but we are generally ready to do so.
                    <br /><br />
                    Member of the “Fairness in Retail” initiative.
                    <br /><br />
                    More information:  https://www.fairness-im-handel.de
                </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </>
      </Section>
      <Divider />
    </div>
  );
};

export default Imprint;
