import React from 'react';
import { SectionHeader } from '../../common/molecules';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from '../CustomButton/CustomButton';
import classes from './BackgroundVideo.module.css';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  textWhite: {
    color: "white"
  },
  titleStyle: {
    marginTop: '25px',
    color: "white"
  }
}));

const BackgroundVideo = ({ src, title, subtitle, btnTextColor, btnBgColor }) => {
  const uiClasses = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.Container} >
      <video autoPlay="autoplay" loop="loop" muted className={classes.Video} >
        <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
      </video>
      <div className={classes.Content}>
        <div className={classes.SubContent} >
          <SectionHeader
            title={title || "We craft beautiful websites and digital products."}
            titleVariant={isMd ? "h2" : "h4"}
            titleClasses={uiClasses.titleStyle}
            subtitle={subtitle || "We design, develop and launch websites and products for startups, companies and ourselves."}
            subtitleVariant="h5"
            subTitleClasses={uiClasses.textWhite}
            ctaGroup={[
              <CustomButton
                textColor={btnTextColor || 'black'}
                bgColor={btnBgColor || 'white'}
                label="Talk To Us"
              />
            ]}
            disableGutter
            data-aos="fade-up"
          />
        </div>
      </div>
    </div>
  )
}

export default BackgroundVideo;