import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const ProductRecommendation = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Product Recommendation"
      mainHeaderTitle="Product Recommendation"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Pomelo Increases Revenue for Fashion Shoppers with Personalization "
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy3/CaseStudy3.png"}
      learnMoreLink="/case-studies/3"
    />
  )
}

export default ProductRecommendation;