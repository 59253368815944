import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ListItem, ListItemText, useMediaQuery } from '@material-ui/core';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 0,
    background: 'transparent',
  },
}));

const CompanyInfo = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos={'fade-up'}
          >
            <ListItem disableGutters key={index} data-aos="fade-up">
              <ListItemText
                primary={item.name}
                secondary={item.title}
                primaryTypographyProps={{
                  variant: 'h6',
                }}
                secondaryTypographyProps={{
                  variant: 'subtitle1',
                }}
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

CompanyInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default CompanyInfo;
