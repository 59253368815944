import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const ProcessAutomation = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Process Automation"
      mainHeaderTitle="Process Automation"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Intelligent Process Automation applied to Insurance Claims"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy5/CaseStudy5.jpeg"}
      learnMoreLink="/case-studies/5"
    />
  )
}

export default ProcessAutomation;