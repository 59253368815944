import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Section } from '../../common/organisms';
import { Image } from '../../common/atoms';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    background: theme.palette.background.default,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 'auto',
    paddingTop: '15px'
  },
  logoImage: {
    width: '36px',
    height: '42px',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'black',
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: '10px',
    letterSpacing: '3px'
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(0,0,0,1)',
    lineHeight: '22px',
    fontFamily: 'SharpSansNoOne-Medium',
    '&:hover': {
      color: theme.palette.alternate.template1,
    },
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
}));

const Footer = props => {
  const { pages, className, ...rest } = props;
  const classes = useStyles();
  const { useCases, solutions, company } = pages;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body1" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem spacing={4} disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body1"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const LandingPages = () => {
    const { menuItems } = useCases.children;
    const menuItems1 = {
      groupTitle: menuItems.groupTitle,
      pages: menuItems.pages.slice(0, 8)
    };

    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={menuItems1} />
        </div>
      </div>
    );
  };

  const SolutionsPages = () => {
    const { menuItems } = solutions.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  const AccountPages = () => {
    const { menuItems } = company.children;

    const morePages = [{
      title: 'Terms & Privacy',
      href: '/privacy',
    },
    {
      title: 'Imprint',
      href: '/imprint',
    }];

    const additionalPages = {
      groupTitle: menuItems.groupTitle,
      pages: [...menuItems.pages, ...morePages]
    };

    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={additionalPages} />
        </div>
      </div>
    );
  };

  const footerPages = {
    findUs: {
      title: 'Find Us',
      children: {
        menuItems: {
          groupTitle: 'Find Us',
          pages: [
            {
              title: 'LinkedIn',
              href: 'https://www.linkedin.com/company/capsulaai',
            }
          ],
        },
      },
    },
    contact: {
      title: 'Contact',
      children: {
        menuItems: {
          groupTitle: 'Contact',
          pages: [
            {
              title: 'info@capsula.ai',
              href: 'mailto:info@capsula.ai',
            },
          ],
        },
      },
    },
  };

  const FindUsPages = () => {
    const { menuItems } = footerPages.findUs.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  const ContactFooterMenu = () => {
    const { menuItems } = footerPages.contact.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={menuItems} />
        </div>
      </div>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.menuListContainer}>
            <Grid container style={{ padding: '20px' }}>
              <Grid item style={{ marginRight: '15px', marginLeft: '15px', width: isMd ? 'auto' : '100%' }}>
                <div className={classes.logoContainer}>
                  <a href="/" title="Capsula">
                    <Image
                      className={classes.logoImage}
                      src={'../../assets/images/CapsulaLogo.png'}
                      alt="Capsula"
                      lazy={false}
                    />
                  </a>
                </div>
              </Grid>
              <Grid item style={{ marginRight: '15px' }}>
                <LandingPages />
              </Grid>
              <Grid item style={{ marginRight: '15px' }}>
                <SolutionsPages />
              </Grid>
              <Grid item style={{ marginRight: '15px' }}>
                <AccountPages />
              </Grid>
              <Grid item style={{ marginRight: '15px' }}>
                <FindUsPages />
              </Grid>
              <Grid item style={{ marginRight: '15px' }}>
                <ContactFooterMenu />
              </Grid>
            </Grid>
          </Grid>
          <Section className={classes.noPaddingBottom}>
            <Divider />
            <Grid container justify="space-between" className={classes.logoContainer}>
              <Typography>Copyright © {new Date().getFullYear()} Capsula AI, Inc. All rights reserved.</Typography>
              <Link target="_blank" to="/privacy">
                <Typography variant="body1" color="textPrimary">Terms & Privacy Policy</Typography>
              </Link>
            </Grid>
          </Section>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
