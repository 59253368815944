import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.gradient1,
    padding: '70px'
  },
  mainHeading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textWhite: {
    color: 'white'
  },
  chip1: {
    minWidth: 150,
    padding: 20,
    borderRadius: 50,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white'
  },
  chip2: {
    minWidth: 200,
    padding: 20,
    borderRadius: 50,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white'
  },
  chip3: {
    minWidth: 250,
    padding: 20,
    borderRadius: 50,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white'
  },
  chip4: {
    minWidth: 300,
    padding: 20,
    borderRadius: 50,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white'
  },
  chipBottom: {
    display: 'inline-flex',
    color: 'white',
    fontSize: '18px',
    marginLeft: '10px',
    marginTop: '10px'
  },
  chipBottomIcon: {
    height: '25px',
    marginRight: '15px',
    filter: 'invert(1)'
  }
}));

const ProcessSection = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root} data-aos="fade-up" {...rest}>
      <div className={classes.mainHeading}>
      <Typography
        variant="h4"
        className={classes.textWhite}
      >
        Our Process, Your Results
      </Typography>
      </div>
      <div className={classes.mainContainer}>
      <div>
        <Chip className={classes.chip1} label="Executive Briefing" />
        <div className={classes.chipBottom}>
          <img className={classes.chipBottomIcon} src="/assets/pages/LandingPage/checked.svg" />
          2 Hours
        </div>
      </div>
      <div>
        <Chip className={classes.chip2} label="Technology Assessment" />
        <div className={classes.chipBottom}>
          <img className={classes.chipBottomIcon} src="/assets/pages/LandingPage/checked.svg" />
          1 - 2 Days
        </div>
      </div>
      <div>
        <Chip className={classes.chip3} label="Implementation of Solution" />
        <div className={classes.chipBottom}>
          <img className={classes.chipBottomIcon} src="/assets/pages/LandingPage/checked.svg" />
          8 - 14 Weeks
        </div>
      </div>
      <div>
        <Chip className={classes.chip4} label="Production" />
        <div className={classes.chipBottom}>
          <img className={classes.chipBottomIcon} src="/assets/pages/LandingPage/checked.svg" />
          3 - 6 Months
        </div>
      </div>
      </div>
    </div>
  );
};

ProcessSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default ProcessSection;
