import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

import SidebarNav from './components/SidebarNav';
import { Image } from '../../common/atoms';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100%',
    maxWidth: 400,
  },
  root: {
    height: '100%',
    padding: theme.spacing(1),
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
  logoContainer: {
    width: 100,
    height: 32,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
    marginTop: '35px',
    marginLeft: '15px'
  },
}));

const Sidebar = props => {
  const { pages, open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={() => onClose()}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.logoContainer}>
        <a href="/" title="Capsula">
          <Image
            className={classes.logoImage}
            src={'../../assets/images/CapsulaNamedLogo.png'}
            alt="Capsula"
            lazy={false}
          />
        </a>
      </div>
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
