import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, colors, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { IconAlternate, SectionHeader } from '../../../common/molecules';
import { CardBase, CardReview, DescriptionListIcon, Section, SectionAlternate } from '../../../common/organisms';
import clsx from 'clsx';
import GetStartedSection from '../../../components/GetStartedSection/GetStartedSection';
import { events } from '../data/data';
import { IconText } from '../../../common/atoms';
import CustomButton from '../../../components/CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#000000'
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  madcapSection: {
    backgroundColor: '#0000008a',
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  }
}));

const Events = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Section>
        <SectionHeader
          title="Events"
          subtitle="Capsula hosted or sponsored events, conferences and webinars."
          titleProps={{
            className: clsx(classes.textBlack, classes.fontWeightBold),
            variant: 'h2',
          }}
          subtitleProps={{
            className: classes.textBlack,
          }}
          ctaGroup={[(
            <CustomButton variant="contained" size="large" label="Talk To Us" />
          )]}
          data-aos="fade-up"
        />
      </Section>
      <SectionAlternate>
      <Grid container spacing={isMd ? 4 : 2}>
        {events.map((review, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={12}
            md={4}
            data-aos="fade-up"
          >
            <CardReview
              variant="outlined"
              text={review.feedback}
            />
          </Grid>
        ))}
      </Grid>
      </SectionAlternate>
      <Section narrow>
        <GetStartedSection />
      </Section>
    </div>
  );
};

export default Events;
