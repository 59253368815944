import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Section } from '../../../common/organisms';
import {
  Content,
  Hero,
} from './components';
import { useParams } from 'react-router';
import { 
  caseStudy1, 
  caseStudy2, 
  caseStudy3,
  caseStudy4,
  caseStudy5,
  caseStudy6,
  caseStudy7,
  caseStudy8
} from './data/articles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const content = {
  1: caseStudy1, 
  2: caseStudy2,
  3: caseStudy3,
  4: caseStudy4,
  5: caseStudy5,
  6: caseStudy6,
  7: caseStudy7,
  8: caseStudy8
};

const BlogArticle = () => {
  const classes = useStyles();
  const { id: blogId } = useParams();

  return (
    <div className={classes.root}>
      <Hero
        cover={content && content[blogId] && content[blogId].cover}
        title={content && content[blogId] && content[blogId].title}
        subtitle={content && content[blogId] && content[blogId].subtitle}
        author={content && content[blogId] && content[blogId].author}
      />
      <Section>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Content data={content[blogId]} />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <SidebarArticles data={sidebarArticles} />
            <SidebarNewsletter className={classes.sidebarNewsletter} />
          </Grid> */}
        </Grid>
      </Section>
      {/* <SectionAlternate>
        <SimilarStories data={similarStories} />
      </SectionAlternate>
      <SectionAlternate className={classes.footerNewsletterSection}>
        <FooterNewsletter />
      </SectionAlternate> */}
    </div>
  );
};

export default BlogArticle;
