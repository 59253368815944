import React from 'react';
import PageUseCases from '../PageUseCases';
import { experiences, useCases, solutions, reviews } from './data';

const DrugDiscovery = () => {
  return (
    <PageUseCases
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Drug Discovery"
      mainHeaderTitle="Drug Discovery"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="New Drug Discovery through using Artificial Intelligence"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/common/case-studies-images/CaseStudy6/CaseStudy6.jpeg"}
      learnMoreLink="/case-studies/6"
    />
  )
}

export default DrugDiscovery;