import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SectionAlternate } from '../../common/organisms';
import Plans from '../../components/Plans/Plans';
import { experiences } from '../LandingPage/data/data';
import { pricings } from './data/data';
import ExperiencesSection from '../../components/ExperiencesSection/ExperiencesSection';
import { SectionHeader } from '../../common/molecules';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.gradient9
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionNoPadding: {
    padding: 0,
    paddingBottom: '100px'
  },
  experienceSection: {
    background: 'white'
  }
}));

const Pricing = () => {
  const classes = useStyles();

  return (
    <div>
      <Plans className={classes.root} data={pricings} />
      <ExperiencesSection data={experiences} className={classes.experienceSection} />
      <SectionAlternate id="agency-reviews">
        <SectionHeader
          title="FAQs"
        />
      </SectionAlternate>
    </div>
  )
};

export default Pricing;
