import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Image, LearnMoreLink } from '../../common/atoms';
import { SectionHeader } from '../../common/molecules';
import SigninForm from '../../components/SigninForm/SigninForm';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    maxWidth: 400,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
    background: theme.palette.background.gradient7,
    minWidth: '100%'
  },
  normalFontWeight: {
    fontWeight: 'normal'
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
    marginTop: 28
  },
  flexGrow: {
    flexGrow: 1,
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
}));

const Singin = () => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <a href="/" title="Capsula">
              <Image
                className={classes.logoImage}
                src={'../../assets/images/CapsulaNamedLogo.png'}
                alt="thefront"
                lazy={false}
              />
            </a>
          </div>
          <div className={classes.flexGrow} />
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              align="right"
            >
              Don’t have an account?{' '}&nbsp;&nbsp;
              <LearnMoreLink
                style={{ color: "black" }}
                color="black"
                title="Sign up"
                typographyProps={{ 
                  variant: "body1"
                }}
              />
            </Typography>
          </Grid>
        <div className={classes.formContainer}>
          <SectionHeader
            title="Log in to your account"
            titleClasses={classes.normalFontWeight}
            titleProps={{
              variant: 'h5',
            }}
          />
          <SigninForm />
        </div>
      </div>
    </div>
  );
};

export default Singin;