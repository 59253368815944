import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const DocumentAI = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Document AI"
      mainHeaderTitle="Document AI"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Unlock insights from documents with machine learning. Make better decisions using document data and make it available to your applications and users."
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/DocumentAI/Document_AI.svg"}
      solutionsSectionSubtitle="Document AI approaches documents like people do"
    />
  )
}

export default DocumentAI;
