export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `Voicebots for customer service`,
    subtitle:
      'Constructing a relied on ecommerce brand name calls for a focus on customer experience technology. Particularly in the apparel sector, traditional, hands-on retail experience has shaped customer assumptions. In words of Ameen Kazerouni, head of artificial intelligence study and also platforms at Zappos, "The holy grail of ecommerce is to get consumers to really feel as comfy as they would in a store-- knowing exactly how an item fits, how it looks, with as much self-confidence as if they were physically trying it on."',
  }
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Interact naturally and accurately',
    description:
      "Deliver more natural customer experiences with virtual agents that support multi-turn conversations with supplemental questions and are built with the deep learning technologies.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Build quickly, deploy universally',
    description:
      'Reduce development time from days to minutes ',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];