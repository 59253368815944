export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    // title: `Voicebots for customer service`,
    subtitle:
      'BuildFax began in 2008 by accumulating dispersed building allow information from throughout the USA as well as supplying it to various other organizations, such as insurance companies, developing examiners, and also economic experts.',
  },
  // {
  //   icon: 'far fa-paper-plane',
  //   title: 'Chatbots for B2C conversations',
  //   subtitle:
  //     'Connect with your customers on their preferred platform, at any time, from anywhere in the world. Whether your customers want to ask common questions or access specific information, text virtual agents offer an instant and satisfying experience for customers who want quick and accurate responses.',
  // }
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Interact naturally and accurately',
    description:
      "Deliver more natural customer experiences with virtual agents that support multi-turn conversations with supplemental questions and are built with the deep learning technologies.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Build quickly, deploy universally',
    description:
      'Reduce development time from days to minutes ',
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];