import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ExperiencesSection from '../../components/ExperiencesSection/ExperiencesSection';
import { Section, SectionAlternate } from '../../common/organisms';
import UseCases from '../../components/UseCases/UseCases';
import GetStartedSection from '../../components/GetStartedSection/GetStartedSection';
import TwoItemsGrid from '../../components/TwoItemsGrid/TwoItemsGrid';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ContentHeader } from '../../components/ContentHeader/ContentHeader';
import { ImageHeader } from '../../components/ImageHeader/ImageHeader';
import SolutionsSection from '../../components/SolutionsSection/SolutionsSection';
import { awards, partners, experiences, promoNumbers } from '../LandingPage/data/data';
import Partners from '../../components/Partners/Partners';
import PartnersSection from '../../components/PartnersSection/PartnersSection';
import Datasets from '../../components/Datasets/Datasets';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  bottomPadding: {
    paddingBottom: '100px'
  },
  sectionNoPadding: {
    padding: 0
  },
  testimonialsBackground: {
    background: 'black',
  },
  useCases: {
    background: theme.palette.background.gradient3,
    minWidth: 'fit-content',
    marginTop: '125px',
    transform: 'skewY(-10deg)',
    '& > *': {
      transform: 'skewY(10deg)'
    }
  }
}));

const Solutions = ({
  // partners,
  useCases,
  solutions,
  reviews,
  mainHeaderLabel,
  mainHeaderImageSrc,
  mainHeaderTitle,
  mainHeaderSubTitle,
  mainHeaderTitleVariant,
  mainHeaderSubTitleVariant,
  solutionsSectionTitle,
  solutionsSectionSubtitle,
  hideUseCases
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Section>
        <TwoItemsGrid
          leftItem={
            <ContentHeader
              align={isMd ? 'center' : 'center'}
              // label={mainHeaderLabel}
              title={mainHeaderTitle}
              subTitle={mainHeaderSubTitle}
              titleVariant={mainHeaderTitleVariant}
              subtitleVariant={mainHeaderSubTitleVariant}
              hideButton 
            />
          }
          rightItem={<ImageHeader src={mainHeaderImageSrc} align="center" />}
        />
      </Section>
      <ExperiencesSection data={experiences} />
      {!hideUseCases &&
        <Section className={classes.useCases}>
          <UseCases mainTitle="Our Technology" data={useCases} />
        </Section>
      }
      <Section>
        <SolutionsSection
          mainTitle={solutionsSectionTitle}
          mainSubTitle={solutionsSectionSubtitle}
          data={solutions}
        />
      </Section>
      <SectionAlternate className={classes.awardsSection}>
        <Section className={classes.sectionNoPadding}>
          <Typography variant="h4" align="center" style={{ marginBottom: 40 }}>
            Awards & Certifications
          </Typography>
          <Partners data={awards} />
        </Section>
      </SectionAlternate>
      <Section>
        <Datasets data={promoNumbers} />
      </Section>
      <PartnersSection data={partners} />
      {/* <SectionAlternate className={classes.sectionNoPaddingTop}> */}
        {/* <Section> */}
        {/* <ContentHeader
          label="Customers"
          title="Trusted by World Class Companies"
          subTitle="Capsula is trusted by leading machine learning teams to develop more accurate models."
          align={isMd ? 'center': 'left'}
        />
        <Section className={classes.sectionNoPadding}>
          <ExperiencesSection data={experiences} />
        </Section> */}
        {/* <Section className={classes.sectionNoPadding}>
          <Partners data={experiences} />
        </Section>
        <Section className={classes.sectionNoPadding}>
          <Partners data={experiences} />
        </Section> */}
        {/* </Section> */}
      {/* </SectionAlternate> */}
      {/* <SectionAlternate className={classes.testimonialsBackground}>
        <Testimonials2 data={reviews} />
      </SectionAlternate> */}
      <Section narrow>
        <GetStartedSection />
      </Section>
    </div>
  );
};

export default Solutions;