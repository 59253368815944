import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControl, TextField, InputAdornment, Input } from '@material-ui/core';
import validate from 'validate.js';
import CustomButton from '../CustomButton/CustomButton';
import { AccountCircle } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputField: {
    background: '#EDF2F7',
    borderRadius: '8px',
    border: 'none',
    height: '48px',
    minWidth: '352px'
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  }
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
    },
  },
};

const SigninForm = () => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formState.isValid) {
      window.location.replace('/');
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid align="center" container spacing={2}>
          <Grid item xs={12}>
            <FormControl className={clsx(classes.margin, classes.formControl)}>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
                className={classes.inputField}
                placeholder="Email"
                label="Email"
                variant="outlined"
                size="medium"
                name="email"
                fullWidth
                onChange={handleChange}
                type="email"
                value={formState.values.email || ''}
                required

              />
            </FormControl>
            {/* <TextField
              className={classes.inputField}
              placeholder="Email"
              label="Email"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              onChange={handleChange}
              type="email"
              value={formState.values.email || ''}
              required
            /> */}
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              className={classes.inputField}
              placeholder="Password"
              label="Password"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              required
            /> */}
            <FormControl className={clsx(classes.margin, classes.formControl)}>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                }
                className={classes.inputField}
                placeholder="Password"
                label="Password"
                variant="outlined"
                size="medium"
                name="password"
                fullWidth
                onChange={handleChange}
                type="password"
                value={formState.values.password || ''}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              align="center"
            >
              Don't remember your password?
            </Typography>
          </Grid>
          <Grid align="center" item xs={12}>
            <CustomButton
              style={{ width: '352px' }}
              size="large"
              type="submit"
              label="Log In"
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SigninForm;