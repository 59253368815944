import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Typography, Button } from '@material-ui/core';
import { Image } from '../../common/atoms';
import { SectionHeader } from '../../common/molecules';
import clsx from 'clsx';
import CustomButton from '../CustomButton/CustomButton';
import { Section } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.gradient3,
    minHeight: 750,
  },
  mainContainer: {
    paddingTop: "100px"
  }
}));

const Hero = props => {
  const { data, title, subtitle, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <Grid className={classes.mainContainer} container justify="space-between" spacing={isMd ? 4 : 0}>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Section>
                <SectionHeader
                  title={
                    <span>
                      {title || "Guaranted Company Growth"}
                    </span>
                  }
                  subtitle={subtitle || "We will help you to understand your leads and you will increase your company growth by converting the qualified leads into sales."}
                  ctaGroup={[
                    <CustomButton label="Talk To Us" />,
                  ]}
                  align={isMd ? 'left' : 'center'}
                  disableGutter
                />
              </Section>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="https://assets.maccarianagency.com/the-front/illustrations/dashboard-extended.svg"
            alt="Dashboard"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Hero;
