import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { IconAlternate, SectionHeader } from '../../../common/molecules';
import { Section, SectionAlternate } from '../../../common/organisms';
import Welcome from '../../../components/Welcome/Welcome';
import TwoItemsGrid from '../../../components/TwoItemsGrid/TwoItemsGrid';
import Video from '../../../components/Video/Video';
import Datasets from '../../../components/Datasets/Datasets';
import Team from '../../../components/Team/Team';
import { team } from './data/data';
import Careers from '../../../components/Careers/Careers';
import GetStartedSection from '../../../components/GetStartedSection/GetStartedSection';
import { promoNumbers } from '../../LandingPage/data/data';
import CustomButton from '../../../components/CustomButton/CustomButton';
import { ImageHeader } from '../../../components/ImageHeader/ImageHeader';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.gradient3
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid white`,
    cursor: 'pointer',
  },
  scrollTopIcon: {
    margin: '0 auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  madcapSection: {
    backgroundColor: '#0000008a',
  },
  adidasSection: {
    backgroundColor: '#003c0580',
  },
  larqSection: {
    backgroundColor: '#2d402f80',
  },
  nikeSection: {
    backgroundColor: '#5f3f3f80',
  },
  videoSection: {
    background: theme.palette.background.gradient3
  }
}));

const About = () => {
  const classes = useStyles();

  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }
      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const firstSectionLeftItem = (
    <SectionHeader
      subtitle="Our diverse team, driven by passion, solves real problems with state-of-the-art technologies. Our roots lie in research and development, which enables us to break down problems into the smallest elements and find sustainable solutions. It is important to us that the AI ​​technologies we develop generate a positive economic and social impact."
      subtitleColor="textPrimary"
      ctaGroup={[
        <CustomButton
          size={isMd ? 'large' : 'medium'}
          label="Talk To Us"
        />
      ]}
      align={isMd ? 'left' : 'center'}
    />
  );

  const firstSectionRightItem = (
    <ImageHeader src="/assets/pages/About/about1.jpg" align="center" />
  );

  const secondSectionRightItem = (
    <SectionHeader
      subtitle="Our mission is to make artificial intelligence accessible to a wide variety of companies."
      subtitleColor="textPrimary"
      ctaGroup={[
        <CustomButton
          size={isMd ? 'large' : 'medium'}
          label="Talk To Us"
        />
      ]}
      align={isMd ? 'left' : 'center'}
    />
  );

  const secondSectionLeftItem = (
    <ImageHeader src="/assets/pages/About/about2.jpg" align="center" />
  );

  const thirdSectionLeftItem = (
    <SectionHeader
      style={{ marginTop: '50px' }}
      subtitle="We use innovative technologies with the aim of maximizing the benefits for our customers. We focus on ensuring that the added value of the solutions offered in areas such as credit risk, fraud prevention, dynamic pricing, recommender systems, computer vision, NLP is decisive."
      subtitleColor="textPrimary"
      ctaGroup={[
        <CustomButton
          size={isMd ? 'large' : 'medium'}
          label="Talk To Us"
        />
      ]}
      align={isMd ? 'left' : 'center'}
    />
  );

  const thirdSectionRightItem = (
    <ImageHeader src="/assets/pages/About/about3.jpg" align="center" />
  );

  const fourthSectionRightItem = (
    <SectionHeader
      subtitle="Our values ​​determine how we act inside and outside the organization. Capsula's most important resource are people: employees, customers and partners. We learn more with every project and expand our horizon of experience. These principles contribute to a large part of our success."
      subtitleColor="textPrimary"
      ctaGroup={[
        <CustomButton
          size={isMd ? 'large' : 'medium'}
          label="Talk To Us"
        />
      ]}
      align={isMd ? 'left' : 'center'}
    />
  );

  const fourthSectionLeftItem = (
    <ImageHeader src="/assets/pages/About/about4.jpg" align="center" />
  );

  return (
    <div className={classes.root}>
      <div className={classes.fullHeight}>
        <Section className={classes.disablePaddingTop}>
          <Welcome
            title="ML Solutions For World Changers"
            subtitle="Capsula is an innovative machine learning company that offers both projects in the field of artificial intelligence (AI) and develops AI products."
          />
        </Section>
        <IconAlternate
          shape="circle"
          fontIconClass="fas fa-chevron-down"
          color={'white'}
          size="small"
          className={classes.scrollIcon}
          onClick={() => scrollTo('next')}
        />
      </div>
      <Section id="next">
        <TwoItemsGrid 
          leftItem={firstSectionLeftItem} 
          rightItem={firstSectionRightItem} 
        />
      </Section>
      {/* <Section>
        <Video />
      </Section> */}
      <Section>
        <TwoItemsGrid 
          leftItem={secondSectionLeftItem} 
          rightItem={secondSectionRightItem} 
        />
      </Section>
      <SectionAlternate>
        <TwoItemsGrid 
          leftItem={thirdSectionLeftItem} 
          rightItem={thirdSectionRightItem}
        />
      </SectionAlternate>
      <Section>
        <Datasets data={promoNumbers} />
      </Section>
      {/* <SectionAlternate>
        <Team data={team} />
      </SectionAlternate> */}
      <Section>
        <TwoItemsGrid 
          leftItem={fourthSectionLeftItem}
          rightItem={fourthSectionRightItem} 
        />
      </Section>
      {/* <SectionAlternate>
        <Careers />
      </SectionAlternate> */}
      <Section narrow>
        <GetStartedSection />
      </Section>
    </div>
  );
};

export default About;
