import React from 'react'
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { Image } from '../../common/atoms';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 420,
    minWidth: 220,
    width: 450,
    height: 350
  },
}));

export const ImageHeader = ({ src, align, width, height }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid
      item
      container
      justify={align || (isMd ? 'flex-end' : 'flex-start')}
      alignItems="center"
      xs={12}
      md={6}
      data-aos={'fade-up'}
    >
      <Image
        src={src || "https://assets.maccarianagency.com/the-front/illustrations/working-on-sofa.svg"}
        alt="Our story"
        className={classes.image}
        style={{
          width,
          height
        }}
      />
    </Grid>
  )
}
