import React from 'react'
import Solutions from '../Solutions';
import { experiences, useCases, solutions, reviews } from './data';

const Translation = () => {
  return (
    <Solutions
      partners={experiences}
      useCases={useCases}
      solutions={solutions}
      reviews={reviews}
      mainHeaderLabel="Page Intelligent Translation"
      mainHeaderTitle="Intelligent Translation"
      mainHeaderTitleVariant={"h4"}
      mainHeaderSubTitle="Dynamically convert in between languages utilizing machine learning"
      mainHeaderSubTitleVariant={"h5"}
      mainHeaderImageSrc={"/assets/pages/Translation/Translation.svg"}
      solutionsSectionSubtitle="Quick, vibrant translation tailored to your content needs. Translation enables organizations to dynamically equate between languages making use of pre-trained or personalized maker learning designs."
    />
  )
}

export default Translation;
