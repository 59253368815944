import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  video: {
    position: 'relative',
  },
  videoCover: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.primary.main,
    opacity: '0.3',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  videoPlayButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: 70,
    zIndex: 1300,
    boxShadow: `0 8px 21px 0 ${theme.palette.cardShadow}`,
    borderRadius: '100%',
  },
}));

const TwoItemsGrid = props => {
  const { className, leftItem, rightItem, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={isMd ? 4 : 2}
      >
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              {leftItem}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          {rightItem}
        </Grid>
      </Grid>
    </div>
  );
};

TwoItemsGrid.propTypes = {
  className: PropTypes.string,
};

export default TwoItemsGrid;
