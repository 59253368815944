import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import { SectionHeader } from '../../common/molecules';
import { CardPromo } from '../../common/organisms';

const Datasets = props => {
  const { data, className, ...rest } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>
            Case Studies
            {/* <Typography component="span" variant="inherit" color="primary">for all</Typography> */}
          </span>
        }
        // subtitle="Large-scale datasets for cutting-edge Computer Vision and Natural Language Processing research."
        fadeUp
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={3}
            data-aos="fade-up"
          >
            <CardPromo
              variant="outlined"
              liftUp
              align={'center'}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              iconSrc={item.icon}
              link={item.link}
              color={item.color}
              titleColor="textPrimary"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Datasets.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default Datasets;
