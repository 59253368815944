import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Swiper from 'swiper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Image } from '../../common/atoms';
import { SectionHeader } from '../../common/molecules';
import { CardJob } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  reviewAuthor: {
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
  },
  authorPhoto: {
    width: '100%',
    height: 300,
    maxWidth: 400,
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(3),
  },
  swiperContainer: {
    position: 'relative',
  },
  swiperWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(7),
    },
  },
  swiperNav: {
    '& .swiper-button-prev, & .swiper-button-next': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding: theme.spacing(3),
      background: theme.palette.primary.main,
      borderRadius: '100%',
      boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
      border: `2px solid ${theme.palette.background.paper}`,
      '&:after': {
        fontSize: 'initial',
        color: theme.palette.background.paper,
      },
    },
  },
}));

const Slider = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: isXs ? 16 : 0,
      pagination: {
        el: '.swiper-container .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-container .swiper-button-next',
        prevEl: '.swiper-container .swiper-button-prev',
      },
    });
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="In the News"
        subtitle="Some of Scale's recent news coverage:"
      />
      <div className={clsx('swiper-container', classes.swiperContainer)}>
        <div className={clsx('swiper-wrapper', classes.swiperWrapper)}>
          <div className="swiper-slide">
            <Grid container spacing={isMd ? 4 : 2}>
              {data.map((item, index) => (
                <Grid
                  key={index}
                  item
                  container
                  alignItems="center"
                  direction="column"
                  xs={12}
                  sm={6}
                  md={4}
                  data-aos="fade-up"
                >
                  <CardJob
                    variant="outlined"
                    liftUp
                    jobTitle={item.jobTitle}
                    badgeColor={item.color}
                    badgeTitle={item.title}
                    jobLocation={item.location}
                    jobType={item.type}
                    jobDate={item.date}
                    companyName={item.companyName}
                    companyLogo={item.companyLogo}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        {!isXs ? null : <div className="swiper-pagination" />}
        {isXs ? null : (
          <div className={classes.swiperNav}>
            <div className={clsx('swiper-button-prev')} />
            <div className={clsx('swiper-button-next')} />
          </div>
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default Slider;
