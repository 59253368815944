import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { CardBase } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  learnMoreLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
}));

const VideoSection = props => {
  const { data, className, ...rest } = props;

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container spacing={isLg ? 4 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            data-aos="fade-up"
          >
            <CardBase liftUp>
              <Typography variant="body1" component="p">
                {item.description}
              </Typography>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

VideoSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default VideoSection;


// import React from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Card, CardContent, Typography, useMediaQuery } from '@material-ui/core';
// import { Grid, Button } from '@material-ui/core';
// import { SectionHeader } from '../../common/molecules';
// import { LearnMoreLink } from '../../common/atoms';

// const useStyles = makeStyles(theme => ({
//   textDark: {
//     color: "black"
//   }
// }));

// const VideoSection =  props => {
//   const { data, className, ...rest } = props;
//   // const classes = useStyles();

//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
//     defaultMatches: true,
//   });

//   return (
//     <div style={{ padding: 10 }} className={className} {...rest}>
//       <Grid container justify="space-between" spacing={isMd ? 4 : 4}>
//         <Grid item xs={12} md={6} data-aos={'fade-up'}>
//           <SectionHeader
//             subtitle={
//               <span>
//                 <Card style={{ marginBottom: '30px' }}>
//                   <CardContent>
//                     <Typography variant="body1" component="p">
//                       Capsula.ai enhances processes. Uncovers effectiveness and also offers insights, making the path to artificial intelligence powered applications smooth as well as economical. It leverages existing systems to maximize value through technology.
//                     </Typography>
//                   </CardContent>
//                 </Card>
//                 <Card style={{ marginBottom: '30px' }}>
//                   <CardContent>
//                     <Typography variant="body1" component="p">
//                       Whether structured or unstructured data, Capsula.ai is able to consume any text-based data without requiring you to organize it first. Consume data from anywhere-- whether it's on-premises or in the cloud, from IoT gadgets to your point-of-sale, CRM and past. We utilize advanced ML/AI to obtain actionable insights fast and accurate.
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </span>
//             }
//             subtitleColor="body1"
//             subtitleVariant="body1"
//             // ctaGroup={[
//             //   <LearnMoreLink title="Talk To Us" />
//             // ]}
//             align={isMd ? 'left' : 'center'}
//             disableGutter
//             titleVariant="h3"
//           />
//         </Grid>
//         <Grid item xs={12} md={6} data-aos={'fade-up'}>
//         <SectionHeader
//             // title="What we do for you?"
//             subtitle={
//               <span>
//                 <Card style={{ marginBottom: '30px' }}>
//                   <CardContent>
//                     <Typography variant="body1" component="p">
//                       By injecting AI into your systems, your teams are totally free to concentrate on work with far better returns and value. We shift them from low-value tasks to high-value tasks.
//                     </Typography>
//                   </CardContent>
//                 </Card>
//                 <Card style={{ marginBottom: '30px' }}>
//                   <CardContent>
//                     <Typography variant="body1" component="p">
//                     Capsula.ai makes it effortless to create large-scale customizable deep learning systems. Our systems generate precise predictions which can be integrated into all aspects of your customer experience and business processes.
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </span>
//             }
//             subtitleColor="body1"
//             subtitleVariant="body1"
//             // ctaGroup={[
//             //   <LearnMoreLink title="Talk To Us" />
//             // ]}
//             align={isMd ? 'left' : 'center'}
//             disableGutter
//             titleVariant="h3"
//           />
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// VideoSection.propTypes = {
//   className: PropTypes.string,
// };

// export default VideoSection;
