import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { useMediaQuery } from '@material-ui/core';
import { Grid, Avatar } from '@material-ui/core';
import { Image } from '../../common/atoms';
import { DescriptionListIcon } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 0,
    background: 'transparent'
  },
  marginTop: {
    marginTop: '17px'
  },
}));

const Integrations = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  // const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos={'fade-up'}
            component="a"
            href={item.link}
            target="_blank"
          >
            <DescriptionListIcon
              icon={
                <Avatar className={classes.avatar}>
                  <Image className={classes.marginTop} src={item.logo} alt={item.name} />
                </Avatar>
              }
              title={item.name}
              subtitle={item.title}
              align="center"
              className={classes.textBlack}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Integrations.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default Integrations;
