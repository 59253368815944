export const experiences = [
  {
    name: 'Airbnb',
    logo: '/assets/common/partners/Logo1final.png',
    style: {
      height: '55px',
      minWidth: '165px',
      marginTop: '-13px'
    }
  },
  {
    name: 'LBFoster',
    logo: '/assets/common/partners/Logo2final.png',
    style: {
      height: '26px',
      minWidth: '125px'
    }
  },
  {
    name: 'Dribbble',
    logo: '/assets/common/partners/Logo3final.png',
    style: {
      height: '80px',
      minWidth: '140px',
      marginTop: '-3px'
    }
  },
  {
    name: 'Instagram',
    logo: '/assets/common/partners/Logo4final.png',
    style: {
      height: '100px',
      marginTop: '-30px'
    }
  },
  {
    name: 'Netflix',
    logo: '/assets/common/partners/Logo5final.png',
    style: {
      height: '35px',
      minWidth: '175px',
      marginTop: '2px'
    }
  }
];

export const useCases = [
  {
    icon: 'far fa-comment',
    title: `AutoML Translation`,
    subtitle:
      'Developers, translators, and localization experts with limited device finding out knowledge can rapidly produce top quality, production-ready models.Translation will certainly educate a custom-made model that you can scale as well as adjust to satisfy your domain-specific needs.',
  },
  {
    icon: 'far fa-paper-plane',
    title: 'Translation API',
    subtitle:
      'Translation API Standard instantly equates texts right into greater than one hundred languages for your internet site and also apps. Translation API Advanced offers the exact same quick, dynamic outcomes you get with Fundamental as well as extra modification functions. Customization matters for domain name- and context-specific terms or expressions.',
  },
  {
    icon: 'far fa-heart',
    title: 'Media Translation API',
    subtitle:
      'Our Media Translation API provides real-time audio translation directly to your material and also applications with enhanced accuracy as well as simplified combination. You can likewise improve individual experience with low-latency streaming translation and also scale swiftly with uncomplicated internationalization.',
  },
];

export const solutions = [
  {
    label: '100% WHITE LABEL',
    title: 'Equate numerous languages',
    description:
      "Our Intelligent Translation pre-trained design sustains greater than one hundred languages.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/brand-platform.svg',
  },
  {
    label: 'FULLY RESPONSIVE',
    title: 'Language detection',
    description:
      "When you do not understand your source message language-- as an example, in user-generated material that doesn't consist of a language code-- our translation algorithms instantly recognize languages with high precision.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/mobiles.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Glossary support',
    description:
    "Our APIs can aid you preserve your brand name in converted content. Merely define the vocabulary you desire focused on, after that save the glossary documents to your translation task, as well as those words as well as expressions will be included in your copy.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Highly scalable',
    description:
    "Our translation items range effortlessly. We offer a charitable daily quota and allow you set lower limits. As well as you can use batch translation with Google Cloud Storage space to decrease the workflow complexity of converting long or several text files.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  },
  {
    label: 'GO SOCIAL',
    title: 'Easy combination',
    description:
    "With our API you do not need to remove text from your record; simply send the HTML and you'll get back converted text.",
    illustration: 'https://assets.maccarianagency.com/the-front/illustrations/dashboard.svg',
  }
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];