import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, Grid } from '@material-ui/core';
import { Image } from '../../common/atoms';
import { CardProduct } from '../../common/organisms';

const useStyles = makeStyles(theme => ({
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    boxShadow: 'none',
    background: 'transparent',
    paddingBottom: theme.spacing(2),
    '& .card-product__content': {
      padding: '50px 100px',
      zIndex: 1,
      background: theme.palette.background.paper,
      width: '100%',
      margin: '0 auto',
      marginTop: theme.spacing(-6),
      borderRadius: `0px 0px 8px 8px`,
      boxShadow: '0 3px 10px 2px rgba(0, 0, 0, 0.1)',
    },
    '& .card-product__media': {
      minHeight: 400,
      '& img': {
        transitionDuration: '.7s',
        transform: 'scale(1.0)',
      },
    },
    '&:hover': {
      '& .card-product__media img': {
        transform: 'scale(1.2)',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .card-product__content': {
        padding: '50px 30px'
      }
    }
  },
  image: {
    objectFit: 'cover',
  },
  blogTitle: {
    fontWeight: 700,
  },
  category: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '15px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: theme.palette.text.secondary
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.primary.main,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
  author: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  date: {
    marginTop: theme.spacing(2, 0),
    marginBottom: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4, 0),
      marginBottom: theme.spacing(2, 0),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  subTitle: {
    marginBottom: '20px'
  },
  descriptionCta: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  centered: {
    justifyContent: 'center'
  },
}));

const BlogItem = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const BlogMediaContent = props => (
    <Image
      {...props}
      className={classes.image}
      lazyProps={{ width: '100%', height: '100%' }}
    />
  );

  const BlogContent = props => (
    <div>
      <div className={classes.category}>
        <Typography variant="h6">
          {props.category}
        </Typography>
      </div>
      <Typography
        variant="h4"
        color="textPrimary"
        className={classes.blogTitle}
      >
        {props.title}
      </Typography>
      {/* <Typography
        variant="body2"
        color="textPrimary"
        className={classes.author}
      >
        <i>
          {props.author.name}
        </i>
      </Typography> */}
      <Typography className={classes.subTitle} variant="body1" color="textPrimary">
        {props.subtitle}
      </Typography>
      <Typography
        variant="body1"
        color="textPrimary"
        className={classes.date}
      >
        <i>
          {props.date}
        </i>
      </Typography>
    </div>
  );

  return (
    <div className={className} {...rest}>
      <Grid container className={classes.centered} spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={10} key={index} data-aos="fade-up">
            <CardProduct
              className={classes.cardProduct}
              mediaContent={
                <BlogMediaContent {...item.cover} alt={item.title} />
              }
              component="a"
              href={item.link}
              cardContent={
                <BlogContent
                  title={item.title}
                  subtitle={item.subtitle}
                  author={item.author}
                  date={item.date}
                  category={item.category}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

BlogItem.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default BlogItem;
